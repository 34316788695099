import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import RenderRoute from "./components/Global/RenderRoute.js";

//Layout
import { Layout } from "./components/Views/Shared/Layout.js";
import LayoutServices from "./components/Views/Services/LayoutServices.js";
//Views
import Homepage from "./components/Views/Homepage/Homepage.js";
import Careers from "./components/Views/Careers/Careers.js";
import Recruitment from "./components/Views/Services/Recruitment/Recruitment.js";
import RecruitmentRequestDetail from "./components/Views/Services/Recruitment/RecruitmentRequestDetail.js";
import CandidateDetail from "./components/Views/Services/Recruitment/CandidateDetail.js";
import Outsourcing from "./components/Views/Services/Outsourcing/Outsourcing.js";
import ConsultingRH from "./components/Views/Services/ConsultingRH/ConsultingRH.js";
import Formation from "./components/Views/Services/Formation/Formation.js";
import FormationDetail from "./components/Views/Services/Formation/FormationDetail.js";
import AssessmentCenter from "./components/Views/Services/AssessmentCenter/AssessmentCenter.js";
import { Guidelines } from "./components/Views/Guidelines/Guidelines.js";

/* Themes */
import defaultTheme from "./themes/default.json";

// STYLES
import "./styles/reset.scss";
import "./styles/config.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Moment Locale
import moment from "moment";
import "moment/locale/pt";
moment.locale("pt");

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
            {/* HOMEPAGE */}
            <RenderRoute exact path="/">
              <Layout>
                <Homepage />
              </Layout>
            </RenderRoute>

            {/* CAREERS */}
            <RenderRoute exact path="/careers">
              <Layout>
                <Careers />
              </Layout>
            </RenderRoute>

            {/* SERVICES */}
            <RenderRoute exact path="/services">
              <Redirect to="/services/recrutamento" />
            </RenderRoute>
            <RenderRoute exact path="/services/recruitment">
              <Redirect to="/services/recrutamento" />
            </RenderRoute>

            {/* SERVICES - RECRUITMENT*/}
            <RenderRoute exact path={"/services/recrutamento"}>
              <Layout>
                <LayoutServices>
                  <Recruitment />
                </LayoutServices>
              </Layout>
            </RenderRoute>

            {/* SERVICES - RECRUITMENT - REQUEST DETAIL*/}
            <RenderRoute exact path={"/request/:id"}>
              <Layout>
                <RecruitmentRequestDetail />
              </Layout>
            </RenderRoute>

            {/* SERVICES - CANDIDATE - REQUEST DETAIL*/}
            <RenderRoute exact path={"/candidate/:id"}>
              <Layout>
                <CandidateDetail />
              </Layout>
            </RenderRoute>

            {/* SERVICES - OUTSOURCING*/}
            <RenderRoute exact path="/services/outsourcing">
              <Layout>
                <LayoutServices>
                  <Outsourcing />
                </LayoutServices>
              </Layout>
            </RenderRoute>

            {/* SERVICES - CONSULTING RH*/}
            <RenderRoute exact path="/services/consultingRH">
              <Layout>
                <LayoutServices>
                  <ConsultingRH />
                </LayoutServices>
              </Layout>
            </RenderRoute>

            {/* SERVICES FORMATION
            <RenderRoute exact path="/services/formation">
              <Layout>
                <LayoutServices>
                  <Formation />
                </LayoutServices>
              </Layout>
            </RenderRoute> */}

            {/* SERVICES - FORMATION DETAIL
            <RenderRoute exact path="/services/formation/:id">
              <Layout formationDetail>
                <FormationDetail />
              </Layout>
            </RenderRoute> */}

            {/* SERVICES - ASSESSMENT CENTER*/}
            <RenderRoute exact path="/services/assessmentCenter">
              <Layout>
                <LayoutServices>
                  <AssessmentCenter />
                </LayoutServices>
              </Layout>
            </RenderRoute>

            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
