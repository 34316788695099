import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//COMPONENTS
import MenuBurguer from "../../Animations/MenuBurguer.js";

//Imgs
import { ReactComponent as ElevusLogo } from "./../../Global/images/elevusLogo.svg";
import { ReactComponent as Facebook } from "./../../Global/icons/facebook.svg";
import { ReactComponent as Instagram } from "./../../Global/icons/instagram.svg";
import { ReactComponent as Linkedin } from "./../../Global/icons/linkedin.svg";
import { ReactComponent as Phone } from "./../../Global/icons/phone.svg";

const StyledNavBar = styled(NanoFlex)`
  height: auto;
  user-select: none;
  .nav {
    height: auto;
    background-color: ${(props) => props.theme.color.main.white};
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 16px;
    position: relative;
    z-index: 9;
    .logoWrapper {
      width: auto;
      a {
        svg {
          width: auto;
          max-height: 60px;
        }
      }
    }
    .contactWrapper {
      gap: 10px;
      svg {
        height: 22px;
        width: auto;
        fill: ${(props) => props.theme.color.colorsPalette.lightOrange};
      }

      &.contactWrapperMobile {
        padding: 0px 10px;

        .contact {
          width: auto;
          gap: 5px;
        }
      }
    }
    .navItemsWrapper {
      display: ${(props) => (props.formationDetail ? "none" : "flex")};
      .navItemsList {
        .navItem {
          width: auto;
          margin-right: 32px;
          a {
            text-decoration: none;
            h5 {
              text-transform: uppercase;
            }
            svg {
              height: 18px;
              width: auto;
            }
            &:hover {
              h5 {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.colorsPalette.blue};
              }
              svg {
                path {
                  transition: ${(props) => props.theme.transition};
                  fill: ${(props) => props.theme.color.colorsPalette.blue};
                }
              }
            }
          }
          .activeNavItem {
            pointer-events: none;
            h5 {
              color: ${(props) => props.theme.color.main.blue};
              font-weight: bold;
            }
          }
        }
        .iconNavItem {
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .languagesList {
        width: auto;
        margin-top: 8px;
        .langItem {
          margin-right: 8px;
          cursor: pointer;
          &:hover {
            h6 {
              font-weight: bold;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .activeLangItem {
          pointer-events: none;
          h6 {
            font-weight: bold;
            color: ${(props) => props.theme.color.colorsPalette.blue};
          }
        }
      }
    }
    .formationTitle {
      display: ${(props) => (props.formationDetail ? "flex" : "none")};
      h4 {
        text-transform: uppercase;
      }
    }
    .navMobile {
      display: none;
      width: auto;
    }
  }

  /* FIXED NAVBAR */
  .fixedNav {
    z-index: 9;
    position: fixed;
    top: 0;
    transition: ${(props) => props.theme.transition};
    padding: 8px 16px;
    .logoWrapper {
      svg {
        height: 32px;
        width: auto;
      }
    }

    .navItemsWrapper {
      .navItemsList {
        .navItem {
          a {
            h5 {
              font-size: 16px;
            }
            svg {
              height: 14px;
            }
          }
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .nav {
      padding: 8px 16px;
      .logoWrapper {
        width: auto;
        a {
          svg {
            width: auto;
            max-height: 40px;
          }
        }
      }
      .navItemsWrapper {
        display: none;
      }
      .navMobile {
        display: ${(props) => (props.formationDetail ? "none" : "flex")};
      }
    }

    /* FIXED NAVBAR */
    .fixedNav {
      .logoWrapper {
        svg {
          height: 24px;
          width: auto;
        }
      }
    }
  }
`;

export class NavBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fixedNav: null,
    };
  }

  // FIXED NAVBAR
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("scroll", () => {
      let fixedNav = "normal";
      if (window.scrollY === 0) {
        fixedNav = null;
      } else {
        fixedNav = "top";
      }
      this.setState({ fixedNav });
      this.state = {
        expand: false,
      };
    });
  }
  // END FIXED NAVBAR

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  scrollToTop = (v) => {
    // window.scrollTo(0, 0);
    this.setState({ selected: v });
  };

  //LANGUAGUE
  onHeaderLanguageChange = () => {
    this.getAllComponentsData();
  };

  changeLanguage = (langId) => {
    window.dispatchEvent(new CustomEvent("languageChange", { detail: langId }));
  };
  //END LANGUAGUE

  //MENU BURGER
  onMenuBurgerOpenChange = (val) => {
    if (this.props.onMenuBurgerOpenChange) this.props.onMenuBurgerOpenChange(val);
  };
  //END MENU BURGER

  render() {
    let langId = this.getLanguage();

    return (
      <StyledNavBar className="navBar" justifyContent="space-between" alignItems="flex-start" formationDetail={this.props.formationDetail}>
        <NanoFlex className={`nav ${this.state.fixedNav ? "fixedNav" : ""}`}>
          <NanoFlex className="logoWrapper">
            <NavLink to="/">
              <ElevusLogo />
            </NavLink>
          </NanoFlex>
          {!this._isMobile && (
            <NanoFlex className="contactWrapper">
              <Phone />
              <Type.h5>(85) 3045 1555 | (85) 99187 6000</Type.h5>
            </NanoFlex>
          )}
          {this._isMobile && (
            <NanoFlex className="contactWrapper contactWrapperMobile">
              <Phone />
              <NanoFlex className="contact" flexDirection="column">
                <Type.h5>(85) 3045 1555</Type.h5>
                <Type.h5>(85) 99187 6000</Type.h5>
              </NanoFlex>
            </NanoFlex>
          )}
          <NanoFlex className="navItemsWrapper" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
            <NanoFlex className="navItemsList" justifyContent="flex-end" alignItems="flex-end">
              <NanoFlex className="navItem">
                <NavLink to="/" exact activeClassName="activeNavItem">
                  <Type.h5>{this.translation.navBar.aboutUs}</Type.h5>
                </NavLink>
              </NanoFlex>
              <NanoFlex className="navItem">
                <NavLink to="/services/" activeClassName="activeNavItem">
                  <Type.h5>{this.translation.navBar.services}</Type.h5>
                </NavLink>
              </NanoFlex>
              <NanoFlex className="navItem">
                <NavLink to="/careers" exact activeClassName="activeNavItem">
                  <Type.h5>{this.translation.navBar.careers}</Type.h5>
                </NavLink>
              </NanoFlex>
              <NanoFlex className="navItem">
                <Link to="#contacts" exact activeClassName="activeNavItem">
                  <Type.h5>{this.translation.navBar.contacts}</Type.h5>
                </Link>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href={this.translation.global.facebookLink} rel="noopener noreferrer" target="_blank">
                  <Facebook />
                </a>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href={this.translation.global.instagramLink} rel="noopener noreferrer" target="_blank">
                  <Instagram />
                </a>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href="https://www.linkedin.com/company/elevus-lisboa/" rel="noopener noreferrer" target="_blank">
                  <Linkedin />
                </a>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="languagesList">
              {!this.isDomainBr() && (
                <NanoFlex className={`langItem ${langId === null ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(null)}>
                  <Type.h6>PT</Type.h6>
                </NanoFlex>
              )}
              {this.isDomainBr() && (
                <NanoFlex className={`langItem ${langId === 1 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(1)}>
                  <Type.h6>BR</Type.h6>
                </NanoFlex>
              )}
              <NanoFlex className={`langItem ${langId === 2 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(2)}>
                <Type.h6>ENG</Type.h6>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="navMobile" justifyContent="flex-end">
            <MenuBurguer open={this.props.menuBurgerOpen} onOpenChange={this.onMenuBurgerOpenChange} />
          </NanoFlex>

          <NanoFlex className="formationTitle" justifyContent="flex-end" alignItems="flex-end">
            <Type.h4>
              <b>{this.translation.services.formation.formationsList.headerTitle}</b>
            </Type.h4>
          </NanoFlex>
        </NanoFlex>
      </StyledNavBar>
    );
  }
}
