import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

// Imgs
import SuccessIMG from "../../../Global/images/elevusSuccess.jpg";
import ExclusiveIMG from "../../../Global/images/elevusExclusive.jpg";
import RpoIMG from "../../../Global/images/elevusRpo.jpg";

const StyledCompanyBlock = styled(NanoFlex)`
  margin-bottom: 56px;
  padding: 0 5%;
  .companyBlockWrapper {
    .blockWrapper {
      height: auto;
      background-color: ${(props) => props.theme.color.main.blue};
      margin-right: 5%;
      .imgWrapper {
        height: 344px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .infoContainer {
        padding: 24px;
        height: auto;
        .titleWrapper {
          margin-bottom: 16px;
          height: auto;
          h5 {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.white};
            margin-bottom: 8px;
          }
          h4 {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.white};
          }
        }
        .infoWrapper {
          height: auto;
          p {
            color: ${(props) => props.theme.color.main.white};
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .successBlock {
      background-color: ${(props) => props.theme.color.main.blue};
    }
    .exclusiveBlock {
      background-color: ${(props) => props.theme.color.main.font};
    }
    .rpoBlock {
      background-color: ${(props) => props.theme.color.colorsPalette.yellow};
      .infoContainer {
        .titleWrapper {
          h5 {
            color: ${(props) => props.theme.color.main.font};
          }
          h4 {
            color: ${(props) => props.theme.color.main.font};
          }
        }
        .infoWrapper {
          p {
            color: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
    .companyBlockWrapper {
      .blockWrapper {
        margin-right: 24px;
      }
    }
  }

  @media only screen and (max-width: 860px) {
    margin-bottom: 24px;
    .companyBlockWrapper {
      .blockWrapper {
        margin-right: 24px;
        .imgWrapper {
          height: 40vh;
          img {
            object-position: top;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) {
    .companyBlockWrapper {
      flex-direction: column;
      .blockWrapper {
        margin-right: 0px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default class CompanyBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledCompanyBlock className="companyBlock">
        <NanoFlex className="companyBlockWrapper" justifyContent="flex-start" alignItems="stretch">
          {/* BLOCK - Elevus Success */}
          <NanoFlex className="blockWrapper successBlock" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="imgWrapper">
              <img src={SuccessIMG} alt="Elevus Success" />
            </NanoFlex>
            <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h5>
                  <b>{this.translation.global.elevus}</b>
                </Type.h5>
                <Type.h4>
                  <b>{this.translation.services.recruitment.companyBlock.success.title}</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="infoWrapper" justifyContent="flex-start">
                <p>{this.translation.services.recruitment.companyBlock.success.info}</p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* BLOCK - Elevus Exclusive */}
          <NanoFlex className="blockWrapper  exclusiveBlock" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="imgWrapper">
              <img src={ExclusiveIMG} alt="Elevus Exclusive" />
            </NanoFlex>
            <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h5>
                  <b>{this.translation.global.elevus}</b>
                </Type.h5>
                <Type.h4>
                  <b>{this.translation.services.recruitment.companyBlock.exclusive.title}</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="infoWrapper" justifyContent="flex-start">
                <p>{this.translation.services.recruitment.companyBlock.exclusive.info}</p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* BLOCK - Elevus RPO */}
          <NanoFlex className="blockWrapper rpoBlock" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="imgWrapper">
              <img src={RpoIMG} alt="Elevus RPO" />
            </NanoFlex>
            <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h5>
                  <b>{this.translation.global.elevus}</b>
                </Type.h5>
                <Type.h4>
                  <b>{this.translation.services.recruitment.companyBlock.rpo.title}</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="infoWrapper" justifyContent="flex-start">
                <p>{this.translation.services.recruitment.companyBlock.rpo.info}</p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledCompanyBlock>
    );
  }
}
