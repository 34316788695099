import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import HeaderServices from "../HeaderServices.js";
import ListBlock from "./ListBlock.js";

//Imgs
import ConsultingRHHeader from "./../../../Global/images/consultingRHHeader.jpg";

const StyledConsultingRH = styled(NanoFlex)`
  height: auto;
`;

export default class ConsultingRH extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledConsultingRH flexDirection={"column"}>
        <HeaderServices changeOrder orange title={this.translation.services.consultingRH.header.title} article={this.translation.services.consultingRH.header.article} src={ConsultingRHHeader} />
        <ListBlock />
      </StyledConsultingRH>
    );
  }
}
