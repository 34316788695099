import React from "react";
import styled from "styled-components";
import moment from "moment";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import Settings from "./../../../Global/Settings.json";
import { elementMatchesAny } from "../../../Global/tools.js";

// Componets
import SimpleButton from "../../../Buttons/SimpleButton.js";
import Modal from "../../../Modals/Modal.js";
import { ModalContactCompany } from "../../../Modals/ModalContactCompany.js";

//Services
import recruitmentRequestService from "../../../../services/RecruitmentRequestService.js";
import candidatesService from "../../../../services/CandidatesService.js";

// Imgs
import { ReactComponent as Bell } from "./../../../Global/icons/bell.svg";
import { ReactComponent as Location } from "./../../../Global/icons/location.svg";
import { ReactComponent as Job } from "./../../../Global/icons/job.svg";
import { ReactComponent as ArrowRight } from "./../../../Global/icons/arrowRight.svg";
import { ReactComponent as Check } from "./../../../Global/icons/check.svg";
import { ReactComponent as ShareIcon } from "./../../../Global/icons/share.svg";

const StyledSearchResult = styled(NanoFlex)`
  margin-bottom: 56px;
  .searchResultWrapper {
    .searchHeader {
      .asideTitle {
        height: auto;
        width: 40%;
        padding: 32px;

        background-color: ${(props) => (props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
        h5 {
          text-transform: uppercase;
          margin-bottom: 8px;
          color: ${(props) => props.theme.color.main.white};
        }
        h4 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.main.white};
        }
        .titleCandidate {
          display: ${(props) => (props.company ? "none" : "flex")};
        }
        .titleCompany {
          display: ${(props) => (props.company ? "flex" : "none")};
        }
      }
      .asideDetailsSearch {
        height: auto;
        .alertsWrapper {
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          .alertBtn {
            cursor: pointer;
            width: auto;
            padding: 24px;
            .iconWrapper {
              width: auto;
              margin-right: 16px;
              svg {
                width: 32px;
                height: auto;
                path {
                  fill: ${(props) => (props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
                }
              }
            }
            .titleAlertBtn {
              width: auto;
            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
        .searchDetailsWrapper {
          height: auto;
          padding: 24px;
          .searchDetails {
            width: auto;
          }
          .cleanSearchBtn {
            width: auto;
            cursor: pointer;
            &:hover {
              p {
                text-decoration: underline;
                color: ${(props) => props.theme.color.colorsPalette.red};
              }
            }
          }
        }
      }
    }
    .searchResultContainer {
      padding: 32px;
      padding-bottom: 0;
      .resultListWrapper {
        width: auto;
        height: auto;
        .resultList {
          width: 344px;
          max-height: 80vh;
          overflow: auto;
          padding: 16px;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          .resultItem {
            height: auto;
            cursor: pointer;
            -webkit-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
            box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
            margin-bottom: 8px;
            .asideInfo {
              background-color: ${(props) => props.theme.color.main.white};
              padding: 16px;
              .infoWrapper {
                height: auto;
                margin-bottom: 16px;
                svg {
                  width: 14px;
                  height: auto;
                  margin-right: 8px;
                  path {
                    fill: ${(props) => (props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                  margin-top: 8px;
                }
              }
              .iconInfo {
                margin-bottom: 8px;
              }
            }
            .companyAside {
              padding: 16px 0 0 0;
              .infoWrapper {
                padding: 0 16px;
              }
              .selectBtnWrapper {
                padding: 8px 16px;
                cursor: pointer;
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey2};
                svg {
                  height: 14px;
                  width: auto;
                  path {
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
                &:hover {
                  opacity: 0.8;
                }
              }
              .selectedItem {
                background-color: ${(props) => props.theme.color.colorsPalette.opacityBlue};
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.white};
                  }
                }
              }
            }
            .arrowWrapper {
              width: auto;
              padding: 16px;
              background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
              svg {
                width: 12px;
                height: auto;
                path {
                  fill: ${(props) => props.theme.color.main.font};
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              -webkit-box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
              box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
            }
          }
          .openedItem {
            pointer-events: none;
            .arrowWrapper {
              background-color: ${(props) => (props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
              svg {
                path {
                  fill: ${(props) => props.theme.color.main.white};
                }
              }
            }
          }
          .companyResultItem.openedItem {
            pointer-events: unset;
            cursor: default;
            .arrowWrapper {
              pointer-events: none;
            }
          }
        }
      }
      .resultDetailWrapper {
        height: auto;
        max-height: 80vh;
        margin-left: 32px;
        padding: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};

        .resultDetails {
          background-color: ${(props) => props.theme.color.main.white};
          -webkit-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
          box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
          .topInfo {
            .titleWrapper {
              padding: 40px 40px 0 40px;
              margin-bottom: 16px;
              height: auto;
              p {
                text-transform: uppercase;
              }
              .shareWrapper {
                width: auto;
                height: auto;
                cursor: pointer;
                a {
                  svg {
                    width: 20px;
                    height: auto;
                    path {
                      fill: ${(props) => props.theme.color.main.font};
                    }
                  }
                  &:hover {
                    svg {
                      path {
                        fill: ${(props) => props.theme.color.colorsPalette.blue};
                      }
                    }
                  }
                }
              }
            }
            .labelResult {
              background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
              width: auto;
              padding: 16px 40px;
              height: auto;
              .infoWrapper {
                margin-bottom: 8px;
                svg {
                  width: 14px;
                  height: auto;
                  margin-right: 8px;
                  path {
                    fill: ${(props) => (props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            .detailsResult {
              padding: 0px 40px;
              max-height: calc(75vh - 326px);
              overflow: auto;
              margin-top: 16px;
              .detailsWrapper {
                margin-bottom: 24px;
                height: auto;
                p {
                  min-height: 20px;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          .bottomInfo {
            height: auto;
            padding: 40px;
            .btnWrapper {
              .button {
                .buttonWrapper {
                  background-color: ${(props) => (props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
                }
              }
            }
          }
        }
      }
    }
    .companyResults {
      padding: 32px;
      padding-bottom: 0;
      .listCompanyResults {
        height: auto;
        padding: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        overflow: auto;
        .itemResult {
          width: 312px;
          min-width: 312px;
          background-color: ${(props) => props.theme.color.main.white};
          padding: 16px;
          margin-right: 8px;
          height: auto;
          cursor: pointer;
          -webkit-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
          box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
          .topInfo {
            background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
            padding: 8px;
            .infoWrapper {
              height: auto;
              margin-bottom: 8px;
              p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              svg {
                width: 14px;
                height: auto;
                margin-right: 8px;
                path {
                  fill: ${(props) => props.theme.color.colorsPalette.blue};
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .middleInfo {
            height: 280px;
            overflow: auto;
            margin: 16px 0 0 0;
            .detailsWrapper {
              margin-bottom: 8px;
              height: auto;
              h6 {
                text-transform: uppercase;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            -webkit-box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
            box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
          }
        }
        .selectedItem {
          background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
          .topInfo {
            background-color: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .btnContainer {
        height: auto;
        margin-top: 8px;
        padding: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
  }
  .noResultsWrapper {
    padding: 40px;
    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
    h5 {
      text-transform: uppercase;
    }
    .cleanSearchBtn {
      margin-top: 16px;
      cursor: pointer;
      &:hover {
        p {
          text-decoration: underline;
          color: ${(props) => props.theme.color.colorsPalette.red};
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1000px) {
    margin-bottom: 32px;
    .searchResultWrapper {
      .searchResultContainer {
        flex-direction: column;
        padding: 16px;
        .results {
          flex-direction: column;
        }
        .resultListWrapper {
          width: 100%;
          .resultList {
            width: 100%;
            align-items: flex-start;
            padding: 8px;
            flex-direction: row;
            align-items: stretch;
            .resultItem {
              justify-content: stretch;
              align-items: stretch;
              width: 280px;
              min-width: 280px;
              margin-bottom: 0;
              margin-right: 8px;
              .arrowWrapper {
                height: auto;
                svg {
                  transform: rotate(90deg);
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .resultDetailWrapper {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 660px) {
    margin-bottom: 16px;
    .searchResultWrapper {
      .searchHeader {
        flex-direction: column;
        .asideTitle {
          width: 100%;
          padding: 16px;
        }
        .asideDetailsSearch {
          .searchDetailsWrapper {
            padding: 16px 16px 0 16px;
          }
        }
      }
      .searchResultContainer {
        padding: 16px 0 0 0;
        .resultDetailWrapper {
          height: auto;
          max-height: unset;
          .resultDetails {
            .topInfo {
              .titleWrapper {
                padding: 16px 16px 0 16px;
              }
              .labelResult {
                padding: 16px;
              }
              .detailsResult {
                padding: 0 16px;
                max-height: 344px;
                min-height: 288px;
                .detailsWrapper {
                  margin-bottom: 8px;
                }
              }
            }
            .bottomInfo {
              padding: 16px;
              flex-direction: column;
              .dateWrapper {
                justify-content: center;
              }
              .btnWrapper {
                justify-content: center;
                .button {
                  .buttonWrapper {
                    min-width: 200px;
                  }
                }
              }
            }
          }
        }
      }
      .companyResults {
        padding: 16px 0 0 0;
        .listCompanyResults {
          .itemResult {
            .bottomInfo {
              .btnWrapper {
                .button {
                  .buttonWrapper {
                    width: 240px;
                    min-width: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class SearchResult extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      results: null,
      selectedResultIndex: 0,
      openModalContact: false,
      selectedCandidates: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getResults();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
      this.getResults();
    }

    if (JSON.stringify(prevState.results) !== JSON.stringify(this.state.results)) {
      this.setState({ selectedCandidates: [] });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getResults = () => {
    if (this.props.filter.isCompany) {
      candidatesService
        .getWebsiteCandidates(this.props.filter)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.setState({ results: data ?? [], selectedResultIndex: 0 });
        });
    } else {
      recruitmentRequestService
        .getRecruitmentRequestWebsiteSharingJobs(this.props.filter)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.setState({ results: data ?? [], selectedResultIndex: 0 });
        });
    }
  };

  onClearFilter = () => {
    if (this.props.onClearFilter) this.props.onClearFilter();
  };

  onCandidateClick = (v) => {
    if (this.state.selectedCandidates.includes(v)) {
      this.setState({
        selectedCandidates: this.state.selectedCandidates.filter((id) => {
          return id !== v;
        }),
      });
    } else {
      this.setState({ selectedCandidates: [...this.state.selectedCandidates, ...[v]] });
    }
  };

  render() {
    if (this.state.results === null) return null;
    let selectedResult = this.state.results ? this.state.results[this.state.selectedResultIndex] : null;
    return (
      <StyledSearchResult className="searchResult" company={this.props.filter?.isCompany}>
        {this.state.results?.length > 0 && (
          <NanoFlex className="searchResultWrapper" flexDirection="column">
            {/* SEARCH HEADER */}
            <NanoFlex className="searchHeader" alignItems="stretch">
              <NanoFlex className="asideTitle" flexDirection="column" alignItems="flex-end">
                <Type.h5>
                  <b>{this.translation.services.recruitment.searchBlock.geral.area}</b>
                </Type.h5>
                <Type.h4 className="titleCandidate">
                  <b>{this.translation.services.recruitment.searchBlock.geral.candidate}</b>
                </Type.h4>
                <Type.h4 className="titleCompany">
                  <b>{this.translation.services.recruitment.searchBlock.geral.company}</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="asideDetailsSearch" flexDirection="column">
                {/* <NanoFlex className="alertsWrapper" justifyContent="flex-start">
                <NanoFlex className="alertBtn" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <Bell />
                  </NanoFlex>
                  <NanoFlex className="titleAlertBtn" flexDirection="column" alignItems="flex-start">
                    <Type.p>Receber alertas</Type.p>
                    <Type.p>desta pesquisa</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex> */}
                <NanoFlex className="searchDetailsWrapper" justifyContent="space-between">
                  <NanoFlex className="searchDetails" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>
                      <b>{this.state.results.length}</b> {this.translation.global.offer}
                      {this.state.results.length > 1 ? "s" : ""} {this.props.filter.function || this.props.filter.location ? this.translation.global.at : ""}
                    </Type.p>
                    {this.props.filter.function && (
                      <Type.p>
                        <b>{this.props.filter.function}</b> {this.props.filter.location ? this.translation.global.at : ""}
                      </Type.p>
                    )}

                    {this.props.filter.location && (
                      <Type.p>
                        <b>{this.props.filter.location}</b>
                      </Type.p>
                    )}
                  </NanoFlex>
                  <NanoFlex className="cleanSearchBtn" alignItems="flex-end" onClick={this.onClearFilter}>
                    <Type.p>{this.translation.global.clearSearch}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* END SEARCH HEADER */}
            {/* SEARCH RESULTS */}
            {/* CANDIDATE */}
            {!this.props.filter.isCompany && (
              <NanoFlex className="searchResultContainer" alignItems="stretch">
                {/* RESULTS LIST*/}
                <NanoFlex className="resultListWrapper">
                  <NanoFlex className="resultList" flexDirection="column" justifyContent="flex-start">
                    {/* RESULT ITEM */}
                    {this.state.results.map((r, i) => {
                      return (
                        <NanoFlex key={`companyItem_${r.recruitmentRequestId}`} className={`resultItem ${this.state.selectedResultIndex === i ? "openedItem" : ""}`} onClick={() => this.setState({ selectedResultIndex: i })}>
                          <NanoFlex className="asideInfo" flexDirection="column" alignItems="flex-start">
                            <NanoFlex className="infoWrapper" justifyContent="flex-start">
                              <Type.p>
                                <b>{r.jobTitle}</b>
                              </Type.p>
                            </NanoFlex>
                            <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                              <Location />
                              <Type.p>{r.location ?? "-"}</Type.p>
                            </NanoFlex>
                            <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                              <Job />
                              <Type.p>{r.workType ?? "-"}</Type.p>
                            </NanoFlex>
                            <NanoFlex className="infoWrapper" justifyContent="flex-start">
                              <Type.h6>
                                {this.translation.global.publishedin} {moment(r.publishedDate).format(Settings.moment.displayDate)}
                              </Type.h6>
                            </NanoFlex>
                          </NanoFlex>

                          <NanoFlex className="arrowWrapper">
                            <ArrowRight />
                          </NanoFlex>
                        </NanoFlex>
                      );
                    })}
                  </NanoFlex>
                </NanoFlex>
                {/* END RESULTS LIST*/}
                {/* RESULT DETAIL*/}
                <NanoFlex className="resultDetailWrapper">
                  {/* CANDIDATE SECTOR */}

                  <NanoFlex className="resultDetails" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                    <NanoFlex className="topInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                      <NanoFlex className="titleWrapper" justifyContent="space-between" alignItems="flex-start">
                        <Type.p>
                          <b>{this.translation.global.opportunityDetails}</b>
                        </Type.p>
                        {/* <Type.p>
                          <b>Oportunidade</b>
                        </Type.p> */}
                        <NanoFlex className="shareWrapper" justifyContent="flex-end" alignItems="flex-end">
                          <a href={"/request/" + selectedResult.recruitmentRequestId} target="_blank">
                            <ShareIcon />
                          </a>
                        </NanoFlex>
                      </NanoFlex>

                      <NanoFlex className="labelResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <NanoFlex className="infoWrapper" justifyContent="flex-start">
                          <Type.p>
                            <b>{selectedResult.jobTitle}</b>
                          </Type.p>
                        </NanoFlex>
                        <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                          <Location />
                          <Type.p>{selectedResult.location}</Type.p>
                        </NanoFlex>
                        <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                          <Job />
                          <Type.p>{selectedResult.workType ?? "-"}</Type.p>
                        </NanoFlex>
                      </NanoFlex>
                      <NanoFlex className="detailsResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        {selectedResult.functionDescription?.trim() !== "" && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            {selectedResult.functionDescription.split("\n").map((s) => (
                              <Type.p>{s}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedProfiles?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>{this.translation.global.profile}</b>
                            </Type.p>
                            {selectedResult.requestedProfiles.map((r) => (
                              <Type.p>{r.name}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedAreas?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>{this.translation.global.area}</b>
                            </Type.p>
                            {selectedResult.requestedAreas.map((r) => (
                              <Type.p>{r.name}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedSkills?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>{this.translation.global.specialization}</b>
                            </Type.p>
                            {selectedResult.requestedSkills.map((r) => (
                              <Type.p>{r.name}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedLanguages?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>{this.translation.global.languages}</b>
                            </Type.p>
                            {selectedResult.requestedLanguages.map((r) => (
                              <Type.p>
                                {r.language.name} {r.level.name}
                              </Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.observations?.trim() !== "" && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            {selectedResult.observations.split("\n").map((s) => (
                              <Type.p>{s}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                      </NanoFlex>
                    </NanoFlex>

                    <NanoFlex className="bottomInfo" justifyContent="space-between">
                      <NanoFlex className="dateWrapper" justifyContent="flex-start">
                        <Type.h6>
                          {this.translation.global.publishedin} {moment(selectedResult.publishedDate).format(Settings.moment.displayDate)}
                        </Type.h6>
                      </NanoFlex>
                      <NanoFlex
                        className="btnWrapper"
                        justifyContent="flex-end"
                        onClick={() => {
                          window.open(`${window.env.FrontEndUrl}/Candidatura?recruitmentRequestId=${selectedResult.recruitmentRequestId}`, "_blank", "noopener,noreferrer");
                        }}>
                        <SimpleButton label={this.translation.global.application} />
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
                {/* END RESULT DETAIL*/}
              </NanoFlex>
            )}
            {/* END CANDIDATE */}
            {/* COMPANY */}
            {this.props.filter.isCompany && (
              <NanoFlex className="searchResultContainer companyResults" flexDirection="column" alignItems="stretch">
                <NanoFlex className="results" alignItems="stretch">
                  {/* RESULTS LIST*/}
                  <NanoFlex className="resultListWrapper">
                    <NanoFlex className="resultList" flexDirection="column" justifyContent="flex-start">
                      {/* RESULT ITEM */}
                      {this.state.results.map((r, i) => {
                        return (
                          <NanoFlex
                            key={`candidateItem_${r.id}`}
                            className={`resultItem companyResultItem ${this.state.selectedResultIndex === i ? "openedItem" : ""}`}
                            onClick={(e) => {
                              /*Prevent Click on Select*/
                              if (elementMatchesAny(e.target, [".selectBtnWrapper"])) return;
                              this.setState({ selectedResultIndex: i });
                            }}>
                            <NanoFlex className="asideInfo companyAside" flexDirection="column" alignItems="flex-start">
                              <NanoFlex className="infoWrapper" justifyContent="flex-start">
                                <Type.p>
                                  <b>{r.id}</b>
                                </Type.p>
                              </NanoFlex>
                              <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                                <Location />
                                <Type.p>{r.cityPreference?.join(", ") ?? "-"}</Type.p>
                              </NanoFlex>
                              <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                                <Job />
                                <Type.p>{r.workType?.name ?? "-"}</Type.p>
                              </NanoFlex>
                              <NanoFlex className="infoWrapper" justifyContent="flex-start">
                                <Type.h6>
                                  {this.translation.global.publishedin} {moment(r.publishedDate).format(Settings.moment.displayDate)}
                                </Type.h6>
                              </NanoFlex>
                              <NanoFlex className={`selectBtnWrapper ${this.state.selectedCandidates.includes(r.id) ? "selectedItem" : ""}`} onClick={() => this.onCandidateClick(r.id)}>
                                <Check />
                              </NanoFlex>
                            </NanoFlex>

                            <NanoFlex className="arrowWrapper">
                              <ArrowRight />
                            </NanoFlex>
                          </NanoFlex>
                        );
                      })}
                    </NanoFlex>
                  </NanoFlex>
                  {/* END RESULTS LIST*/}
                  {/* RESULT DETAIL*/}
                  <NanoFlex className="resultDetailWrapper">
                    {/* COMPANY SECTOR */}

                    <NanoFlex className="resultDetails" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                      <NanoFlex className="topInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <NanoFlex className="titleWrapper" justifyContent="space-between" alignItems="flex-start">
                          <Type.p>
                            <b>{this.translation.global.candidateDetails}</b>
                          </Type.p>
                          {/* <Type.p>
                          <b>Candidato</b>
                        </Type.p> */}
                          <NanoFlex className="shareWrapper" justifyContent="flex-end" alignItems="flex-end">
                            <a href={"/candidate/" + selectedResult.id} target="_blank">
                              <ShareIcon />
                            </a>
                          </NanoFlex>
                        </NanoFlex>
                        <NanoFlex className="labelResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                          <NanoFlex className="infoWrapper" justifyContent="flex-start">
                            <Type.p>
                              <b>{selectedResult.jobTitle}</b>
                            </Type.p>
                          </NanoFlex>
                          <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                            <Location />
                            <Type.p>{selectedResult.cityPreference?.join(", ") ?? "-"}</Type.p>
                          </NanoFlex>
                          <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                            <Job />
                            <Type.p>{selectedResult.workType?.name ?? "-"}</Type.p>
                          </NanoFlex>
                        </NanoFlex>
                        <NanoFlex className="detailsResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                          {selectedResult.externalObservations && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              {selectedResult.externalObservations.split("\n").map((s) => (
                                <Type.p>{s}</Type.p>
                              ))}
                            </NanoFlex>
                          )}
                          {selectedResult.professionalExperience && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.experience}</b>
                              </Type.h6>
                              {selectedResult.professionalExperience && <Type.p>{selectedResult.professionalExperience}</Type.p>}
                            </NanoFlex>
                          )}
                          {selectedResult.experience && selectedResult.experience?.period > 0 && selectedResult?.experience?.periodType?.name && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.professionalExperienceTime}</b>
                              </Type.h6>
                              <Type.p>
                                {selectedResult.experience.period} {selectedResult.experience.periodType.name}
                              </Type.p>
                            </NanoFlex>
                          )}
                          {selectedResult.salaryRangePreference && (selectedResult.salaryRangePreference.min > 0 || selectedResult.salaryRangePreference.max > 0) && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.salary}</b>
                              </Type.h6>
                              <Type.p>{`${selectedResult.salaryRangePreference.min} - ${selectedResult.salaryRangePreference.max} ${selectedResult.isLiquidSalary ? "Líquidos" : "Brutos"}`}</Type.p>
                            </NanoFlex>
                          )}
                          {selectedResult.habilitation?.length > 0 && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.qualification}</b>
                              </Type.h6>
                              {selectedResult.habilitation.courseName && <Type.p>{selectedResult.habilitation.courseName}</Type.p>}
                              {selectedResult.habilitation.degree?.name && <Type.p>{selectedResult.habilitation.degree.name}</Type.p>}
                            </NanoFlex>
                          )}
                          {selectedResult.profiles?.length > 0 && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.profile}</b>
                              </Type.h6>
                              {selectedResult.profiles.map((r) => (
                                <Type.p key={r}>{r}</Type.p>
                              ))}
                            </NanoFlex>
                          )}
                          {selectedResult.areas?.length > 0 && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.area}</b>
                              </Type.h6>
                              {selectedResult.areas.map((r) => (
                                <Type.p key={r}>{r}</Type.p>
                              ))}
                            </NanoFlex>
                          )}
                          {selectedResult.skills?.length > 0 && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.specialization}</b>
                              </Type.h6>
                              {selectedResult.skills.map((r) => (
                                <Type.p key={r}>{r}</Type.p>
                              ))}
                            </NanoFlex>
                          )}
                          {selectedResult.languages?.length > 0 && (
                            <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                              <Type.h6>
                                <b>{this.translation.global.languages}</b>
                              </Type.h6>
                              {selectedResult.languages.map((r) => (
                                <Type.p key={`${r.language.name}_${r.level.name}`}>
                                  {r.language.name} {r.level.name}
                                </Type.p>
                              ))}
                            </NanoFlex>
                          )}
                          {/* {selectedResult.functionDescription?.trim() !== "" && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            {selectedResult.functionDescription.split("\n").map((s) => (
                              <Type.p>{s}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedProfiles?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>Perfil</b>
                            </Type.p>
                            {selectedResult.requestedProfiles.map((r) => (
                              <Type.p>{r.name}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedAreas?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>Técnicas</b>
                            </Type.p>
                            {selectedResult.requestedAreas.map((r) => (
                              <Type.p>{r.name}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedSkills?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>Especialização</b>
                            </Type.p>
                            {selectedResult.requestedSkills.map((r) => (
                              <Type.p>{r.name}</Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.requestedLanguages?.length > 0 && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.p>
                              <b>Línguas</b>
                            </Type.p>
                            {selectedResult.requestedLanguages.map((r) => (
                              <Type.p>
                                {r.language.name} {r.level.name}
                              </Type.p>
                            ))}
                          </NanoFlex>
                        )}
                        {selectedResult.observations?.trim() !== "" && (
                          <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            {selectedResult.observations.split("\n").map((s) => (
                              <Type.p>{s}</Type.p>
                            ))}
                          </NanoFlex>
                        )} */}
                        </NanoFlex>
                      </NanoFlex>
                      <NanoFlex className="bottomInfo" justifyContent="space-between">
                        <NanoFlex className="dateWrapper" justifyContent="flex-start">
                          <Type.h6>
                            {this.translation.global.publishedin} {moment(selectedResult.publishedDate).format(Settings.moment.displayDate)}
                          </Type.h6>
                        </NanoFlex>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
                {/* END RESULT DETAIL*/}
                {/* <NanoFlex className="listCompanyResults" justifyContent="flex-start" alignItems="flex-start">

                  {this.state.results.map((r, i) => {
                    return (
                      <NanoFlex className={`itemResult ${this.state.selectedCandidates.includes(r.id) ? "selectedItem" : ""}`} flexDirection="column" onClick={() => this.onCandidateClick(r.id)}>
                        <NanoFlex className="topInfo" flexDirection="column">
                          <NanoFlex className="infoWrapper" justifyContent="flex-start">
                            <Type.p>
                              <b>{r.id}</b>
                            </Type.p>
                          </NanoFlex>
                          <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start" title={r.cityPreference?.join(", ") ?? "-"}>
                            <Location />
                            <Type.p>{r.cityPreference?.join(", ") ?? "-"}</Type.p>
                          </NanoFlex>
                          <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                            <Job />
                            <Type.p>{r.workType?.name ?? "-"}</Type.p>
                          </NanoFlex>
                        </NanoFlex>
                        <NanoFlex className="middleInfo" justifyContent="flex-start" alignItems="flex-start">
                          <NanoFlex className="detailsResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            {r.professionalExperience && (
                              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Type.h6>
                                  <b>Experiência</b>
                                </Type.h6>
                                {r.professionalExperience && <Type.p>{r.professionalExperience}</Type.p>}
                              </NanoFlex>
                            )}
                            {r.habilitation?.length > 0 && (
                              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Type.h6>
                                  <b>Qualificação</b>
                                </Type.h6>
                                {r.habilitation.courseName && <Type.p>{r.habilitation.courseName}</Type.p>}
                                {r.habilitation.degree?.name && <Type.p>{r.habilitation.degree.name}</Type.p>}
                              </NanoFlex>
                            )}
                            {r.profiles?.length > 0 && (
                              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Type.h6>
                                  <b>Perfil</b>
                                </Type.h6>
                                {r.profiles.map((r) => (
                                  <Type.p>{r}</Type.p>
                                ))}
                              </NanoFlex>
                            )}
                            {r.areas?.length > 0 && (
                              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Type.h6>
                                  <b>Técnicas</b>
                                </Type.h6>
                                {r.areas.map((r) => (
                                  <Type.p>{r}</Type.p>
                                ))}
                              </NanoFlex>
                            )}
                            {r.skills?.length > 0 && (
                              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Type.h6>
                                  <b>Especialização</b>
                                </Type.h6>
                                {r.skills.map((r) => (
                                  <Type.p>{r}</Type.p>
                                ))}
                              </NanoFlex>
                            )}
                            {r.languages?.length > 0 && (
                              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Type.h6>
                                  <b>Línguas</b>
                                </Type.h6>
                                {r.languages.map((r) => (
                                  <Type.p>
                                    {r.language.name} {r.level.name}
                                  </Type.p>
                                ))}
                              </NanoFlex>
                            )}
                          </NanoFlex>
                        </NanoFlex>
                      </NanoFlex>
                    );
                  })}

                </NanoFlex>
                 */}
                <NanoFlex className="btnContainer">
                  <NanoFlex className="btnWrapper">
                    <SimpleButton label={this.translation.global.contact} clickAction={() => this.setState({ openModalContact: true })} disabled={this.state.selectedCandidates.length === 0} />
                    <Modal show={this.state.openModalContact} content={<ModalContactCompany selectedCandidates={this.state.selectedCandidates} cancelAction={() => this.setState({ openModalContact: false })} nextAction={() => this.setState({ selectedCandidates: [], openModalContact: false })} />} />
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            )}
            {/* END COMPANY */}
            {/* END SEARCH RESULTS */}
          </NanoFlex>
        )}
        {(!this.state.results || this.state.results?.length === 0) && (
          <NanoFlex className="noResultsWrapper" flexDirection="column">
            <Type.h5>{this.translation.services.recruitment.searchBlock.geral.noResults}</Type.h5>
            <NanoFlex className="cleanSearchBtn" onClick={this.onClearFilter}>
              <Type.p>{this.translation.global.clearSearch}</Type.p>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledSearchResult>
    );
  }
}
