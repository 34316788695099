import React from "react";
import { NavLink, Link } from "react-router-dom";

import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import { ReactComponent as Facebook } from "./../../Global/icons/facebook.svg";
import { ReactComponent as Instagram } from "./../../Global/icons/instagram.svg";
import { ReactComponent as Linkedin } from "./../../Global/icons/linkedin.svg";

const StyledMenuMobile = styled(NanoFlex)`
  display: ${(props) => (props.show ? "flex" : "none")};
  z-index: 7;
  background: ${(props) => props.theme.color.main.white};
  position: fixed;
  top: 0;
  left: 0;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  padding-top: 96px;

  .navItemsWrapper {
    display: ${(props) => (props.formationDetail ? "none" : "flex")};
    height: auto;
    .navItemsList {
      padding: 0 32px;
      .navItem {
        margin-bottom: 16px;
        justify-content: flex-start;
        a {
          text-decoration: none;
          h5 {
            text-transform: uppercase;
          }
          svg {
            height: 18px;
            width: auto;
          }
          &:hover {
            h5 {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.colorsPalette.blue};
            }
            svg {
              path {
                transition: ${(props) => props.theme.transition};
                fill: ${(props) => props.theme.color.colorsPalette.blue};
              }
            }
          }
        }
        .activeNavItem {
          pointer-events: none;
          h5 {
            color: ${(props) => props.theme.color.main.blue};
            font-weight: bold;
          }
          p {
            color: ${(props) => props.theme.color.main.blue};
            font-weight: bold;
          }
        }
      }

      .iconNavItem {
        margin-right: 16px;
        width: auto;
        &:last-child {
          margin-right: 0;
        }
      }

      .secondaryNav {
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        margin-bottom: 16px;
        padding-top: 16px;
        .secItemTitle {
          width: 100%;
          margin-bottom: 0px;
          padding-bottom: 16px;
          border-bottom: 0;
          a {
            pointer-events: none;
          }
        }
        .secItem {
          margin-bottom: 16px;
          padding-left: 16px;
          border-bottom: 0;
          a {
            p {
              text-transform: uppercase;
            }
          }
          &:last-child {
          }
        }
      }

      .navIconsWrapper {
        margin-top: 16px;
      }
    }
    .languagesList {
      width: auto;
      margin-top: 8px;
      .langItem {
        margin-right: 8px;
        cursor: pointer;
        &:hover {
          h6 {
            font-weight: bold;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .activeLangItem {
        pointer-events: none;
        h6 {
          font-weight: bold;
          color: ${(props) => props.theme.color.colorsPalette.blue};
        }
      }
    }
  }

  @keyframes slide-bottom {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

class MenuMobile extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected ? this.props.selected : 1,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) this.props.onMenuBurgerOpenChange(false);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //LANGUAGUE
  onHeaderLanguageChange = () => {
    this.getAllComponentsData();
  };

  changeLanguage = (langId) => {
    window.dispatchEvent(new CustomEvent("languageChange", { detail: langId }));
  };
  //END LANGUAGUE
  onSelectClick = (v) => {
    this.setState({ selected: v });
  };

  render() {
    let langId = this.getLanguage();
    return (
      <StyledMenuMobile className="menuMobile" show={this.props.menuBurgerOpen} justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="navItemsWrapper" flexDirection="column">
          <NanoFlex className="navItemsList" flexDirection="column">
            <NanoFlex className="navItem">
              <NavLink to="/" exact activeClassName="activeNavItem">
                <Type.h5>{this.translation.navBar.aboutUs}</Type.h5>
              </NavLink>
            </NanoFlex>
            <NanoFlex className="secondaryNav" flexDirection="column">
              <NanoFlex className="navItem secItemTitle">
                <NavLink to="/services/" activeClassName="activeNavItem">
                  <Type.h5>{this.translation.navBar.services}</Type.h5>
                </NavLink>
              </NanoFlex>
              <NanoFlex className="navItem secItem">
                <NavLink to="/services/" exact activeClassName="activeNavItem">
                  <Type.p>{this.translation.services.navBar.recruitment}</Type.p>
                </NavLink>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="navItem secItem">
                <NavLink to="/services/outsourcing" exact activeClassName="activeNavItem">
                  <Type.p>{this.translation.services.navBar.outsourcing}</Type.p>
                </NavLink>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="navItem secItem">
                <NavLink to="/services/consultingRH" exact activeClassName="activeNavItem">
                  <Type.p>{this.translation.services.navBar.consultingRH}</Type.p>
                </NavLink>
              </NanoFlex>
              {/* ITEM 
              <NanoFlex className="navItem secItem">
                <NavLink to="/services/formation" exact activeClassName="activeNavItem">
                  <Type.p>{this.translation.services.navBar.formation}</Type.p>
                </NavLink>
              </NanoFlex> */}
              {/* ITEM */}
              <NanoFlex className="navItem secItem">
                <NavLink to="/services/assessmentCenter" exact activeClassName="activeNavItem">
                  <Type.p>{this.translation.services.navBar.assessmentCentet}</Type.p>
                </NavLink>
              </NanoFlex>
            </NanoFlex>

            <NanoFlex className="navItem">
              <NavLink to="/careers" exact activeClassName="activeNavItem">
                <Type.h5>{this.translation.navBar.careers}</Type.h5>
              </NavLink>
            </NanoFlex>
            <NanoFlex className="navItem">
              <Link to="#contacts">
                <Type.h5>{this.translation.navBar.contacts}</Type.h5>
              </Link>
            </NanoFlex>
            <NanoFlex className="navIconsWrapper">
              <NanoFlex className="navItem iconNavItem">
                <a href={this.translation.global.facebookLink} rel="noopener noreferrer" target="_blank">
                  <Facebook />
                </a>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href={this.translation.global.instagramLink} rel="noopener noreferrer" target="_blank">
                  <Instagram />
                </a>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href="https://www.linkedin.com/company/elevus-lisboa/" rel="noopener noreferrer" target="_blank">
                  <Linkedin />
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="languagesList">
            {!this.isDomainBr() && (
              <NanoFlex className={`langItem ${langId === null ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(null)}>
                <Type.h6>PT</Type.h6>
              </NanoFlex>
            )}
            {this.isDomainBr() && (
              <NanoFlex className={`langItem ${langId === 1 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(1)}>
                <Type.h6>BR</Type.h6>
              </NanoFlex>
            )}
            <NanoFlex className={`langItem ${langId === 2 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(2)}>
              <Type.h6>ENG</Type.h6>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledMenuMobile>
    );
  }
}

//Set default props
MenuMobile.defaultProps = {
  show: false,
};

export default MenuMobile;

// --- // Documentation // --- //
/*
import MenuMobile from './NavBar/MenuMobile.js';

<MenuMobile />
*/
