import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import HeaderServices from "../HeaderServices.js";
import SearchBlock from "./SearchBlock.js";
import AboutRecruitment from "./AboutRecruitment.js";
import SearchResult from "./SearchResult.js";
import CompanyBlock from "./CompanyBlock.js";
import SelectBtnsBlock from "./../../../Blocks/SelectBtnsBlock.js";

//Imgs
import RecruitmentHeader from "./../../../Global/images/recruitmentHeader.jpg";
import SelectBtnBlock02 from "./../../../Global/images/selectBtnBlock02.jpg";

const StyledRecruitment = styled(NanoFlex)`
  height: auto;
`;

export default class Recruitment extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledRecruitment flexDirection={"column"}>
        <HeaderServices changeOrder title={this.translation.services.recruitment.header.title} article={this.translation.services.recruitment.header.article} src={RecruitmentHeader} />
        <SearchBlock filter={this.state.filter} onFilterChange={(f) => this.setState({ filter: f })} isCompany={this.state.filter?.isCompany} />

        {/* ------------
        O bloco "searchResult" SÓ APARECE quando é realizada uma pesquisa no candidato ou na empresa 
        Alterar a adicionar a prop "company" quando a pesquisa for feita na área Empresa
        <SearchResult company />
        ------------ */}
        {this.state.filter && <SearchResult filter={this.state.filter} onClearFilter={() => this.setState({ filter: null }, () => window.dispatchEvent(new Event("clearFilter")))} />}

        {/* ------------
        O bloco "AboutRecruiment" DESAPARECE quando é realizada uma pesquisa no candidato ou na empresa 
        ------------ */}
        {!this.state.filter && <AboutRecruitment />}

        {/* ------------
        O bloco "CompanyBlock" SÓ APARECE quando é realizada 
        uma pesquisa na EMPRESA 
        ------------ */}
        {this.state.filter && this.state.filter.isCompany && <CompanyBlock />}

        <SelectBtnsBlock src={SelectBtnBlock02} title={this.translation.services.recruitment.selectBlock.title} options={this.translation.services.recruitment.selectBlock.options} />
      </StyledRecruitment>
    );
  }
}
