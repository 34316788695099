import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import FormationDetailInfo from "./FormationDetailInfo.js";
import FormationsList from "./FormationsList.js";

//Imgs

const StyledFormationDetail = styled(NanoFlex)`
  height: auto;
  .formationList {
    .formationListWrapper {
      .formationHeader {
        display: none;
      }
    }
  }
`;

export default class FormationDetail extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFormationDetail flexDirection={"column"}>
        <FormationDetailInfo render={this.props.render} />
        <FormationsList />
      </StyledFormationDetail>
    );
  }
}
