import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// Componets
import { CTAButton } from "../../Buttons/CTAButton.js";

const StyledHeaderServices = styled(NanoFlex)`
  height: auto;
  margin-bottom: 64px;
  .headerWrapper {
    height: 70vh;

    .headerInfo {
      order: ${(props) => (props.changeOrder ? "2" : "1")};
      width: 60%;
      background-color: ${(props) => (props.orange ? props.theme.color.colorsPalette.orange : props.red ? props.theme.color.colorsPalette.red : props.violet ? props.theme.color.colorsPalette.violet : props.theme.color.main.blue)};
      padding: 64px;
      .headerTitle {
        height: auto;
        h2 {
          b {
            color: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .headerArticle {
        margin: 32px 0;
        height: auto;
        h5 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
      .ctaContainer {
        height: auto;
      }
    }
    .headerImg {
      width: 40%;
      height: 100%;
      overflow: hidden;
      order: ${(props) => (props.changeOrder ? "1" : "2")};
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
    .headerWrapper {
      flex-direction: column;
      height: auto;
      .headerInfo {
        order: 2;
        width: 100%;
        padding: 32px;
        .headerTitle {
          height: auto;
          h2 {
            font-size: 46px;
            line-height: 50px;
          }
        }
        .headerArticle {
          margin: 24px 0;
        }
      }
      .headerImg {
        order: 1;
        width: 100%;
        height: 65vh;
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .headerWrapper {
      .headerInfo {
        padding: 24px 16px;
        .headerTitle {
          h2 {
            font-size: 36px;
            line-height: 40px;
          }
        }
        .headerArticle {
          margin: 16px 0;
        }
      }
      .headerImg {
        height: 45vh;
      }
    }
  }
`;

export default class HeaderServices extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledHeaderServices className="headerServices" orange={this.props.orange} red={this.props.red} violet={this.props.violet} changeOrder={this.props.changeOrder}>
        <NanoFlex className="headerWrapper">
          <NanoFlex className="headerInfo" flexDirection="column">
            <NanoFlex className="headerTitle" justifyContent="flex-start">
              <Type.h2>
                <b>{this.props.title}</b>
              </Type.h2>
            </NanoFlex>
            <NanoFlex className="headerArticle" justifyContent="flex-start">
              <Type.h5>{this.props.article}</Type.h5>
            </NanoFlex>
            <NanoFlex className="ctaContainer" justifyContent="flex-start">
              <CTAButton white link={"#contacts"} />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="headerImg">
            <img src={this.props.src} alt="image" />
          </NanoFlex>
        </NanoFlex>
      </StyledHeaderServices>
    );
  }
}
