import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import { Carousel } from "react-responsive-carousel";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import { duplicateArray } from "./../../../Global/tools.js";

// Componets
import FormationItem from "./FormationItem.js";

//Services
import formationsService from "../../../../services/FormationsService.js";

// Imgs
import Formation01 from "./../../../Global/images/formation01.jpg";
import Formation02 from "./../../../Global/images/formation02.jpg";
import Formation03 from "./../../../Global/images/formation03.jpg";
import Formation04 from "./../../../Global/images/formation04.jpg";
import DistanceType from "./../../../Global/icons/distanceType.svg";

const StyledFormationsList = styled(NanoFlex)`
  height: auto;
  margin-bottom: 56px;
  .formationListWrapper {
    .formationHeader {
      padding: 0 32px;
      .formationTitle {
        h3 {
          text-transform: uppercase;
        }
      }
    }
    .formationsList {
      padding: 32px;
      padding-bottom: 0;
      position: relative;
      height: auto;
      .carouselWrapper {
        overflow: hidden;
        /*CAROUSEL*/
        .carousel-root {
          width: 100%;
          height: 100%;
          .carousel-slider {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            .slider-wrapper {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              align-content: center;
              .slider {
                display: flex;
                width: 33%;
                align-items: flex-start;
                .slide {
                  background-color: unset;
                  margin-right: 24px;
                  width: calc(100% - 24px);
                  min-width: calc(100% - 24px);
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
            .control-dots {
              display: none;
            }
            .carousel-status {
              display: none;
            }
          }
          .carousel {
            .axis-vertical {
              display: none;
            }
          }
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 900px) {
    .formationListWrapper {
      .formationHeader {
        .formationTitle {
          justify-content: center;
        }
      }
      .formationsList {
        padding: 16px 0 0 0;
        .carouselWrapper {
          .carousel-root {
            .carousel-slider {
              .slider-wrapper {
                .slider {
                  /* width: 90vw; */
                  min-width: 310px;
                  .slide {
                    width: calc(100% - 8px);
                    min-width: calc(100% - 8px);
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class FormationsList extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      formations: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getFormations();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFormations = () => {
    formationsService
      .getWebsiteFormations()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ formations: data });
      });
  };

  render() {
    return (
      <StyledFormationsList className="formationList">
        <NanoFlex className="formationListWrapper" flexDirection="column">
          <NanoFlex className="formationHeader">
            <NanoFlex className="formationTitle" justifyContent="flex-start">
              <Type.h3>
                <b>{this.translation.services.formation.formationsList.headerTitle}</b>
              </Type.h3>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="formationsList">
            <NanoFlex className="carouselWrapper">
              <Carousel infiniteLoop emulateTouch={this._isMobile} useKeyboardArrows stopOnHover>
                {/* ITEM */}
                {duplicateArray(this.state.formations, 4)?.map((f) => (
                  <NanoFlex className="formationListItem">
                    <FormationItem id={f.formationId} hours={f.totalHours} type={f.type} iconType={DistanceType} title={f.title} subtitle={f.subTitle} description={f.description} />
                  </NanoFlex>
                ))}
              </Carousel>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledFormationsList>
    );
  }
}
