import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import CloudBottom from "./../../Global/images/cloudbottom.svg";

const StyledVideoCareers = styled(NanoFlex)`
  height: auto;
  background-image: url(${CloudBottom});
  background-size: cover;
  margin-bottom: 64px;
  .videoWrapper {
    width: 60%;
    padding-top: 32px;
    position: relative;
    .videoContainer {
      width: 100%;
      overflow: hidden;
      position: relative;
      background-color: ${(props) => props.theme.color.main.blue};
      padding-bottom: calc(52.4633%);
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .articleWrapper {
      margin-top: 32px;
      .titleWrapper {
        margin-bottom: 16px;
        text-align: center;
        h4 {
          b {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.blue};
          }
        }
      }
      .subtitleWrapper {
        text-align: center;
        h5 {
          b {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.blue};
          }
        }
        a {
          text-decoration: none;
          margin-left: 4px;
          h5 {
            &:hover {
              b {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.colorsPalette.blue};
              }
            }
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
    .videoWrapper {
      width: 80%;
    }
  }
  @media only screen and (max-width: 560px) {
    .videoWrapper {
      width: 90%;
      .articleWrapper {
        .titleWrapper {
          margin-bottom: 8px;
          h4 {
            font-size: 22px;
            line-height: 24px;
          }
        }
        .subtitleWrapper {
          flex-direction: column;
        }
      }
    }
  }
`;

export default class VideoCareers extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledVideoCareers className="videoCareers" flexDirection={"column"}>
        <NanoFlex className="videoWrapper" flexDirection={"column"}>
          <div className="videoContainer">
            <video controls poster={this.props.videoPoster}>
              <source src={this.props.video} type="video/mp4" />
            </video>
          </div>
          <NanoFlex className="articleWrapper" flexDirection={"column"}>
            <NanoFlex className="titleWrapper">
              <Type.h4>
                <b>{this.translation.careers.videoBlock.title}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="subtitleWrapper">
              <Type.h5>
                <b>{this.translation.careers.videoBlock.subtitle}</b>
              </Type.h5>
              <a href={`mailto:${this.translation.careers.videoBlock.email}`}>
                <Type.h5>
                  <b>{this.translation.careers.videoBlock.email}</b>
                </Type.h5>
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledVideoCareers>
    );
  }
}
