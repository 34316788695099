import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

// Componets
import Input from "../../../Forms/Input.js";
import SimpleButton from "../../../Buttons/SimpleButton.js";

//Imgs
import SearchRecruitmentIMG from "./../../../Global/images/searchRecruitmentIMG.jpg";
import RecruitmentIMG from "./../../../Global/images/recruitmentIMG.jpg";
import CompanyIMG from "./../../../Global/images/companyIMG.jpg";

const StyledSearchBlock = styled(NanoFlex)`
  height: auto;
  .searchBlockWrapper {
    .imgWrapper {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }

    .searchWrapper {
      .searchBox {
        height: auto;
        .searchContainer {
          background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
          padding: 10%;
          .searchBoxHeader {
            margin-bottom: 40px;
            height: auto;
            h5 {
              text-transform: uppercase;
              margin-bottom: 16px;
            }
            h3 {
              text-transform: uppercase;
            }
          }
          .formContainer {
            .titleForm {
              margin-bottom: 16px;
              height: auto;
            }
            .formWrapper {
              margin-bottom: 24px;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
        .orangeBox {
          background-color: ${(props) => props.theme.color.colorsPalette.lighterOrange};
        }
        .imgContainer {
          width: 100%;
          max-height: 588px;
          display: none;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .candidateResult {
      .companySearch {
        .searchContainer {
          display: none;
        }
        .imgContainer {
          display: flex;
        }
      }
    }

    .companyResult {
      .candidateSearch {
        .searchContainer {
          display: none;
        }
        .imgContainer {
          display: flex;
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 660px) {
    .searchBlockWrapper {
      .searchWrapper {
        flex-direction: column;
        .searchBox {
          .searchContainer {
            .searchBoxHeader {
              margin-bottom: 16px;
              h5 {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
      .candidateResult {
        .companySearch {
          display: none;
        }
      }

      .companyResult {
        .candidateSearch {
          display: none;
        }
      }
    }
  }
`;

export default class SearchBlock extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      candidateFunction: "",
      candidateLocation: "",

      companyFunction: "",
      companyLocation: "",
    };
  };

  componentDidMount() {
    super.componentDidMount();

    window.addEventListener("clearFilter", this.onClearFilter, false);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("clearFilter", this.onClearFilter, false);
  }

  onFilterChange = (filter) => {
    if (this.props.onFilterChange) this.props.onFilterChange(filter);
  };

  isCandidateSearchDisabled = () => {
    return this.state.candidateFunction?.trim() == "" && this.state.candidateLocation?.trim() == "";
  };

  onCandidateSearch = () => {
    this.onFilterChange({
      page: 1,
      pageSize: 25,
      function: this.state.candidateFunction,
      location: this.state.candidateLocation,
      isCompany: false,
    });
  };

  isCompanySearchDisabled = () => {
    return this.state.companyFunction?.trim() == "" && this.state.companyLocation?.trim() == "";
  };

  onCompanySearch = () => {
    this.onFilterChange({
      page: 1,
      pageSize: 25,
      function: this.state.companyFunction,
      location: this.state.companyLocation,
      isCompany: true,
    });
  };

  onClearFilter = () => {
    this.setState(this.getDefaultState());
  };

  render() {
    return (
      <StyledSearchBlock className="searchBlock">
        <NanoFlex className="searchBlockWrapper" flexDirection="column">
          {/* ------------ 
           Na NanoFlex a baixo "searchWrapper" adicionar 
          a class "companyResult" e "candidateResult"
          conforme a pesquisa feita, por default fica sem nenhuma dessas classes 
          ------------ */}
          <NanoFlex className={`searchWrapper ${this.props.isCompany === true ? "companyResult" : this.props.isCompany === false ? "candidateResult" : ""}`} justifyContent="stretch" alignItems="stretch" alignContent="stretch">
            <NanoFlex className="searchBox candidateSearch" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
              <NanoFlex className="searchContainer orangeBox" flexDirection="column">
                <NanoFlex className="searchBoxHeader" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h5>
                    <b>{this.translation.services.recruitment.searchBlock.geral.area}</b>
                  </Type.h5>
                  <Type.h3>
                    <b>{this.translation.services.recruitment.searchBlock.geral.candidate}</b>
                  </Type.h3>
                </NanoFlex>
                <NanoFlex className="formContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <NanoFlex className="titleForm" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.translation.services.recruitment.searchBlock.geral.searchBy}</Type.p>
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.services.recruitment.searchBlock.form.function} placeholder={this.translation.services.recruitment.searchBlock.form.function} defaultValue={this.state.candidateFunction} onChangeAction={(val) => this.setState({ candidateFunction: val })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.services.recruitment.searchBlock.form.location} placeholder={this.translation.services.recruitment.searchBlock.form.location} defaultValue={this.state.candidateLocation} onChangeAction={(val) => this.setState({ candidateLocation: val })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <SimpleButton orange label={this.translation.global.search} clickAction={this.onCandidateSearch} disabled={this.isCandidateSearchDisabled()} />
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="imgContainer">
                <img src={RecruitmentIMG} alt="Recruitment" />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="searchBox companySearch" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
              <NanoFlex className="searchContainer" flexDirection="column">
                <NanoFlex className="searchBoxHeader" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.h5>
                    <b>{this.translation.services.recruitment.searchBlock.geral.area}</b>
                  </Type.h5>
                  <Type.h3>
                    <b>{this.translation.services.recruitment.searchBlock.geral.company}</b>
                  </Type.h3>
                </NanoFlex>
                <NanoFlex className="formContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <NanoFlex className="titleForm" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.translation.services.recruitment.searchBlock.geral.searchBy}</Type.p>
                  </NanoFlex>

                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.services.recruitment.searchBlock.form.function} placeholder={this.translation.services.recruitment.searchBlock.form.function} defaultValue={this.state.companyFunction} onChangeAction={(val) => this.setState({ companyFunction: val })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.services.recruitment.searchBlock.form.location} placeholder={this.translation.services.recruitment.searchBlock.form.location} defaultValue={this.state.companyLocation} onChangeAction={(val) => this.setState({ companyLocation: val })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <SimpleButton label={this.translation.global.search} clickAction={this.onCompanySearch} disabled={this.isCompanySearchDisabled()} />
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="imgContainer">
                <img src={CompanyIMG} alt="Recruitment" />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledSearchBlock>
    );
  }
}
