import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import HomepageHeaderIMG from "./../../Global/images/homepageHeader.jpg";

// Componets
import { CTAButton } from "../../Buttons/CTAButton.js";

const StyledHeaderHomepage = styled(NanoFlex)`
  .articleWrapper {
    background: linear-gradient(360deg, #edacb4 0%, #f1b5bd 33.33%, #f1b8bf 57.81%, #f3bcc2 100%);
    padding: 5%;
    height: auto;
    .textWrapper {
      h1 {
        b {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }
  .imgWrapper {
    height: auto;
    max-height: 656px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    position: relative;
    align-items: flex-end;
    .articleWrapper {
      position: absolute;
      background: unset;
      justify-content: flex-end;
      .textWrapper {
        height: auto;
      }
      .ctaContainer {
        height: auto;
      }
    }
  }
`;

export default class HeaderHomepage extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledHeaderHomepage className="headerHomepage" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
        <NanoFlex className="articleWrapper" flexDirection={"column"}>
          <NanoFlex className="textWrapper" justifyContent="flex-start">
            <Type.h1>
              <b>{this.translation.homepage.headerTitle}</b>
            </Type.h1>
          </NanoFlex>
          <NanoFlex className="ctaContainer" justifyContent="flex-start">
            <CTAButton link={"#contacts"} />
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="imgWrapper">
          <img src={HomepageHeaderIMG} alt="Homepage Header Image" />
        </NanoFlex>
      </StyledHeaderHomepage>
    );
  }
}
