import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "./../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import { decodeHtml } from "./../../../Global/tools.js";

// Imgs
import Formation02 from "./../../../Global/images/formation02.jpg";
import DistanceType from "./../../../Global/icons/distanceType.svg";
import { ReactComponent as Content } from "./../../../Global/icons/content.svg";
import { ReactComponent as ArrowDown } from "./../../../Global/icons/arrowDown.svg";
import { ReactComponent as ElevusLogo } from "./../../../Global/images/elevusLogo.svg";

//Services
import formationsService from "../../../../services/FormationsService.js";

const StyledFormationDetailInfo = styled(NanoFlex)`
  height: auto;
  margin-bottom: 56px;
  .formationDetailInfoWrapper {
    .asideMenu {
      width: 464px;
      height: auto;
      background-color: ${(props) => props.theme.color.colorsPalette.violet};
      .headerMenu {
        height: auto;
        padding: 24px 16px;
        border-bottom: 1px solid ${(props) => props.theme.color.main.white};
        .iconWrapper {
          width: auto;
          height: auto;
          margin-right: 16px;
          svg {
            height: 40px;
            width: auto;
          }
        }
        .titleMenu {
          width: auto;
          height: auto;
          h4 {
            color: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .dropdownMenuWrapper {
        height: auto;
        .dropdownItem {
          border-bottom: 1px solid ${(props) => props.theme.color.main.white};
          .dropdownHeader {
            cursor: pointer;
            padding: 16px;
            .titleItem {
              width: auto;
              height: auto;
              p {
                color: ${(props) => props.theme.color.main.white};
              }
            }
            .iconWrapper {
              width: auto;
              height: auto;
              svg {
                width: 21px;
                height: auto;
              }
            }
            &:hover {
              background-color: ${(props) => props.theme.color.colorsPalette.mediumViolet};
              transition: ${(props) => props.theme.transition};
            }
          }
          .dropdownDetails {
            display: none;
            padding: 16px;
            p {
              color: ${(props) => props.theme.color.main.white};
              margin-bottom: 4px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .tabItem {
              margin-left: 8px;
            }
          }
        }
        .expandedItem {
          .dropdownHeader {
            background-color: ${(props) => props.theme.color.colorsPalette.darkViolet};
            .iconWrapper {
              transform: rotate(180deg);
            }
          }
          .dropdownDetails {
            display: flex;
          }
        }
      }
    }
    .asideDetails {
      .headerDetails {
        .imgWrapper {
          height: auto;
          max-height: 50vh;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .titleFormation {
          height: auto;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          padding: 32px;
          .typeContainer {
            height: auto;
            margin-bottom: 32px;
            .typeWrapper {
              height: auto;
              .type {
                height: auto;
                width: auto;
                margin-bottom: 8px;
              }
              .hours {
                height: auto;
                width: auto;
                padding: 4px 8px;
                background-color: ${(props) => props.theme.color.main.font};
                p {
                  color: ${(props) => props.theme.color.main.white};
                }
              }
            }
            .iconType {
              width: 80px;
              height: auto;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          .titleWrapper {
            .title {
              height: auto;
              margin-bottom: 32px;
            }
            .subtitle {
              height: auto;
            }
          }
        }
      }
      .detailsContainer {
        padding: 40px;
        .detailWrapper {
          margin-bottom: 40px;
          .detailTitle {
            margin-bottom: 16px;
            h5 {
              text-transform: uppercase;
            }
          }
          .listDetail {
            .itemListDetail {
              margin-left: 4px;
              p {
                &:before {
                  content: "•";
                  color: ${(props) => props.theme.color.main.font};
                  margin-right: 4px;
                }
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        .detailArticle {
          ol,
          ul {
            margin-left: 40px;
            list-style: revert;
          }

          span {
            font-size: 16px !important;
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
  }
`;

export default class FormationDetailInfo extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      formation: null,
      modules: null,
      selectedModule: null,
      imageError: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();

    this.GetComponentData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.render.match.params.id !== this.props.render.match.params.id) {
      this.setState(this.getDefaultState(), () => {
        this.GetComponentData();
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  GetComponentData() {
    this.GetFormationBasicWebsiteInfo();
    this.GetFormationModulesWebsite();
  }

  GetFormationBasicWebsiteInfo = () => {
    formationsService
      .GetFormationBasicWebsiteInfo(this.props.render.match.params.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (!data || data.id <= 0) this.props.render.history.push("/services/formation");
        else {
          this.setState({ formation: data });
        }
      });
  };

  GetFormationModulesWebsite = () => {
    formationsService
      .GetFormationModulesWebsite(this.props.render.match.params.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ modules: data, selectedModule: data[0]?.id });
      });
  };

  onModuleClick = (id) => {
    this.setState({ selectedModule: id });
  };

  render() {
    if (!this.state.formation) return null;

    return (
      <StyledFormationDetailInfo className="formationDetailInfo">
        <NanoFlex className="formationDetailInfoWrapper" alignItems="stretch">
          <NanoFlex className="asideMenu" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="headerMenu" justifyContent="flex-start">
              <NanoFlex className="iconWrapper">
                <Content />
              </NanoFlex>
              <NanoFlex className="titleMenu">
                <Type.h4>
                  <b>Conteúdos</b>
                </Type.h4>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="dropdownMenuWrapper" flexDirection="column" justifyContent="flex-start">
              {/* ITEM */}
              {this.state.modules?.map((m) => (
                <NanoFlex key={m.id} className={`dropdownItem ${m.id === this.state.selectedModule ? "expandedItem" : ""}`} flexDirection="column" onClick={() => this.onModuleClick(m.id)}>
                  <NanoFlex className="dropdownHeader" justifyContent="space-between">
                    <NanoFlex className="titleItem" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                      <Type.p>
                        {m.reference} | {m.hours}h
                      </Type.p>
                      <Type.p>
                        <b>{m.name}</b>
                      </Type.p>
                    </NanoFlex>
                    <NanoFlex className="iconWrapper">
                      <ArrowDown />
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="dropdownDetails" flexDirection="column" alignItems="flex-start">
                    <Type.p>{m.description}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              ))}
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="asideDetails" flexDirection="column">
            <NanoFlex className="headerDetails" alignItems="stretch">
              <NanoFlex className="imgWrapper">
                {this.state.imageError && <img src={`/images/elevusLogo.png`} alt="Formation" />}
                {!this.state.imageError && <img src={`${window.env.API_URL}/formations/GetFormationImageWebsite/${this.state.formation.id}`} alt="Formation" onError={() => this.setState({ imageError: true })} />}
              </NanoFlex>
              <NanoFlex className="titleFormation" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="typeContainer" justifyContent="space-between">
                  <NanoFlex className="typeWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <NanoFlex className="type" justifyContent="flex-start">
                      <Type.p>{this.state.formation.typeName}</Type.p>
                    </NanoFlex>
                    <NanoFlex className="hours" justifyContent="flex-start">
                      <Type.p>{this.state.formation.totalHours} horas</Type.p>
                    </NanoFlex>
                  </NanoFlex>
                  <Link to="#contacts">
                    <NanoFlex className="iconType" justifyContent="flex-end">
                      <img src={DistanceType} alt="formation type" />
                    </NanoFlex>
                  </Link>
                </NanoFlex>
                <NanoFlex className="titleWrapper" flexDirection="column" alignItems="flex-start">
                  <NanoFlex className="title" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h4>
                      <b>{this.state.formation.title}</b>
                    </Type.h4>
                  </NanoFlex>
                  <NanoFlex className="subtitle" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.state.formation.subTitle}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="detailsContainer" flexDirection="column">
              {/* BLOCK TO REPEAT */}
              {this.state.formation.description && this.state.formation.description.trim() !== "" && (
                <NanoFlex className="detailWrapper" flexDirection="column">
                  <NanoFlex className="detailTitle" justifyContent="flex-start">
                    <Type.h5>
                      <b>Detalhes do curso</b>
                    </Type.h5>
                  </NanoFlex>
                  <NanoFlex className="detailArticle" justifyContent="flex-start">
                    <div dangerouslySetInnerHTML={{ __html: decodeHtml(this.state.formation.description) }}></div>
                  </NanoFlex>
                </NanoFlex>
              )}
              {/* BLOCK TO REPEAT */}
              {this.state.formation.objectives && this.state.formation.objectives.trim() !== "" && (
                <NanoFlex className="detailWrapper" flexDirection="column">
                  <NanoFlex className="detailTitle" justifyContent="flex-start">
                    <Type.h5>
                      <b>OBJECTIVOS</b>
                    </Type.h5>
                  </NanoFlex>
                  <NanoFlex className="detailArticle" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <div dangerouslySetInnerHTML={{ __html: decodeHtml(this.state.formation.objectives) }}></div>
                  </NanoFlex>
                </NanoFlex>
              )}
              {/* BLOCK TO REPEAT */}
              {this.state.formation.target && this.state.formation.target.trim() !== "" && (
                <NanoFlex className="detailWrapper" flexDirection="column">
                  <NanoFlex className="detailTitle" justifyContent="flex-start">
                    <Type.h5>
                      <b>DESTINATÁRIOS</b>
                    </Type.h5>
                  </NanoFlex>
                  <NanoFlex className="detailArticle" justifyContent="flex-start">
                    <Type.p>{this.state.formation.target}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              )}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledFormationDetailInfo>
    );
  }
}
