import { BaseService } from "./BaseService";
import { getUrlParams } from "../components/Global/tools.js";

export class FormationsService extends BaseService {
  getWebsiteFormations() {
    return this.request("Formations/GetWebsiteFormations", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  GetFormationBasicWebsiteInfo(formationId) {
    return this.request(`Formations/GetFormationBasicWebsiteInfo/${formationId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  GetFormationModulesWebsite(formationId) {
    return this.request(`Formations/GetFormationModulesWebsite/${formationId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }
}

const formationsService = new FormationsService();

export default formationsService;
