import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

//Views
import { NavBar } from "./NavBar.js";
import MenuMobile from "./MenuMobile.js";
import { ContactBlock } from "./ContactBlock.js";
import { Footer } from "./Footer.js";

const StyledLayout = styled.div`
  overflow: ${(props) => (!props.scrollable ? "hidden" : "auto")};
  height: ${(props) => (!props.scrollable ? "100%" : "auto")};
  overflow-x: hidden;
`;

export class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuBurgerOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.render !== this.props.render) {
      this.scrollInto();
      this.onMenuBurgerOpenChange(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad = () => {
    this.scrollInto();
  };

  scrollInto = () => {
    setTimeout(() => {
      let element = this.props.render.location.hash;
      if (element && element.trim() !== "" && element.trim() !== "#") {
        let el = document.getElementById(element.substring(1));
        let yOffset = document.getElementsByClassName("nav")[0].clientHeight * -1;
        let y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
    }, 250);
  };

  onMenuBurgerOpenChange = (val) => {
    this.setState({ isMenuBurgerOpen: val });
  };

  render() {
    return (
      <StyledLayout flexDirection={"column"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"flex-start"} scrollable={!this.state.isMenuBurgerOpen}>
        <NavBar formationDetail={this.props.formationDetail} menuBurgerOpen={this.state.isMenuBurgerOpen} onMenuBurgerOpenChange={this.onMenuBurgerOpenChange} />
        <MenuMobile menuBurgerOpen={this.state.isMenuBurgerOpen} onMenuBurgerOpenChange={this.onMenuBurgerOpenChange} />
        {React.cloneElement(this.props.children, { render: this.props.render })}
        <ContactBlock />
        <Footer />
      </StyledLayout>
    );
  }
}
