import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import { decodeHtml } from "./../../../Global/tools.js";

import Formation02 from "./../../../Global/images/formation02.jpg";
import { ReactComponent as ElevusLogo } from "./../../../Global/images/elevusLogo.svg";

const StyledFormationItem = styled(NanoFlex)`
  height: auto;

  /* width: auto; */
  .formationItemWrapper {
    /* width: 544px; */
    width: 100%;
    min-height: 368px;
    background-color: ${(props) => props.theme.color.main.white};
    border: 1px solid ${(props) => props.theme.color.main.white};
    cursor: pointer;
    .imgWrapper {
      width: 100%;
      height: 280px;
      position: relative;
      overflow: hidden;
      user-select: none;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .hoursWrapper {
        width: auto;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 16px;
        padding: 4px 8px;
        background-color: ${(props) => props.theme.color.main.font};
        h6 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
    .formationInfoWrapper {
      padding: 16px 0;
      .typeWrapper {
        height: auto;
        margin-bottom: 8px;
        .type {
          width: auto;
        }
        .iconType {
          width: 32px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .titleWrapper {
        height: auto;
        .title {
          margin-bottom: 16px;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
        }
        .subtitle {
          p {
            text-align: left;
          }
        }
      }
      .descriptionWrapper {
        height: 0px;
        overflow: hidden;
        transition: ${(props) => props.theme.transition};
        p {
          text-align: left;
        }
      }
    }
    &:hover {
      transition: ${(props) => props.theme.transition};
      border: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
      -webkit-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
      box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
      .imgWrapper {
        img {
          width: 120%;
          height: 120%;
          object-fit: cover;
          transition: ${(props) => props.theme.transition};
        }
      }
      .formationInfoWrapper {
        padding: 16px;
        .descriptionWrapper {
          margin-top: 16px;
          height: auto;
          overflow: hidden;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 900px) {
    .formationItemWrapper {
      width: 310px;
    }
  }
`;

export default class FormationItem extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      imageError: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onFormationClick = () => {
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/services/formation/${this.props.id}`} />;
    }
    return (
      <StyledFormationItem className="formationItem" onClick={this.onFormationClick}>
        <NanoFlex className="formationItemWrapper" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="imgWrapper">
            {this.state.imageError && <img src={`/images/elevusLogo.png`} alt="Formation" />}
            {!this.state.imageError && <img src={`${window.env.API_URL}/formations/GetFormationImageWebsite/${this.props.id}`} alt="Formation" onError={() => this.setState({ imageError: true })} />}
            <NanoFlex className="hoursWrapper">
              <Type.h6>{this.props.hours} Horas</Type.h6>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="formationInfoWrapper" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="typeWrapper" justifyContent="space-between">
              <NanoFlex className="type">
                <Type.h6>{this.props.type}</Type.h6>
              </NanoFlex>
              {/* <NanoFlex className="iconType">
                <img src={this.props.iconType} alt="icon type" />
              </NanoFlex> */}
            </NanoFlex>
            <NanoFlex className="titleWrapper" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="title" justifyContent="flex-start">
                <Type.p>
                  <b>{this.props.title}</b>
                </Type.p>
              </NanoFlex>
              <NanoFlex className="subtitle" justifyContent="flex-start">
                <Type.p>{this.props.subtitle}</Type.p>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="descriptionWrapper" justifyContent="flex-start">
              <div dangerouslySetInnerHTML={{ __html: decodeHtml(this.props.description) }}></div>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledFormationItem>
    );
  }
}
