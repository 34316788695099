import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import BaseComponent from "./../BaseComponent.js";
import NanoFlex from "./../Global/NanoFlex.js";
import Type from "./../Global/Typography.js";

//Imgs
import { ReactComponent as ArrowCTA } from "../Global/icons/arrowCTA.svg";

const StyledCTAButton = styled(NanoFlex)`
  width: auto;
  position: relative;
  a {
    text-decoration: none;
    .textButton {
      width: auto;
      height: auto;
      h3 {
        font-size: ${(props) => (props.small ? "28px" : "")};
        text-transform: uppercase;
        color: ${(props) => (props.white ? props.theme.color.main.white : props.theme.color.main.blue)};
      }
    }
    .arrowWrapper {
      margin-top: ${(props) => (props.small ? "-20px" : "-22px")};
      margin-right: ${(props) => (props.small ? "-252px" : "-366px")};
      width: auto;
      svg {
        width: ${(props) => (props.small ? "96px" : "auto")};
        path {
          fill: ${(props) => (props.white ? props.theme.color.main.white : props.theme.color.main.blue)};
        }
      }
    }
  }
  &:hover {
    a {
      .arrowWrapper {
        transition: ${(props) => props.theme.transition};
        margin-right: ${(props) => (props.small ? "-260px" : "-376px")};
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    a {
      .textButton {
        h3 {
          font-size: 28px;
        }
      }
      .arrowWrapper {
        margin-top: -20px;
        margin-right: -252px;
        svg {
          width: 96px;
        }
      }
      &:hover {
        .arrowWrapper {
          margin-right: -260px;
        }
      }
    }
  }
  @media only screen and (max-width: 340px) {
    a {
      .textButton {
        h3 {
          font-size: 20px;
          line-height: inherit;
        }
      }
      .arrowWrapper {
        display: none;
      }
    }
  }
`;

export class CTAButton extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledCTAButton className="CTAbutton" flexDirection="column" white={this.props.white} small={this.props.small}>
        <Link to={this.props.link}>
          <NanoFlex className="textButton">
            <Type.h3>
              <b>{this.translation.global.letsTalk}</b>
            </Type.h3>
          </NanoFlex>
          <NanoFlex className="arrowWrapper">
            <ArrowCTA />
          </NanoFlex>
        </Link>
      </StyledCTAButton>
    );
  }
}
