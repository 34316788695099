import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import HeaderServices from "../HeaderServices.js";
import ListBlock from "./ListBlock.js";
import SelectBtnsBlock from "../../../Blocks/SelectBtnsBlock.js";

//Imgs
import AssessmentCenterHeader from "./../../../Global/images/assessmentCenterHeader.jpg";
import AssessmentCenterSelectBlock from "./../../../Global/images/assessmentCenterSelectBlock.jpg";

const StyledAssessmentCenter = styled(NanoFlex)`
  height: auto;
`;

export default class AssessmentCenter extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledAssessmentCenter flexDirection={"column"}>
        <HeaderServices red changeOrder title={this.translation.services.assessmentCenter.header.title} article={this.translation.services.assessmentCenter.header.article} src={AssessmentCenterHeader} />
        <ListBlock />
        <SelectBtnsBlock src={AssessmentCenterSelectBlock} options={this.translation.services.assessmentCenter.selectBlock.options} />
      </StyledAssessmentCenter>
    );
  }
}
