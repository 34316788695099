import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

const StyledServicesNavBar = styled(NanoFlex)`
  height: auto;
  user-select: none;

  .nav {
    height: auto;
    background-color: ${(props) => props.theme.color.main.white};
    position: relative;
    z-index: 8;
    padding: 24px;
    .navItemsList {
      .navItem {
        a {
          text-decoration: none;
          p {
            text-transform: uppercase;
          }
          &:hover {
            p {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.colorsPalette.blue};
            }
          }
        }
      }
      .activeNavItem {
        pointer-events: none;
        p {
          color: ${(props) => props.theme.color.main.blue};
          font-weight: bold;
        }
      }
    }
  }

  /* FIXED NAVBAR */
  .fixedNav {
    z-index: 8;
    position: fixed;
    /* top: 60px; */
    top: 50px;
    transition: ${(props) => props.theme.transition};
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    display: none;
  }
`;

export default class ServicesNavBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fixedNav: null,
    };
  }

  // FIXED NAVBAR
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("scroll", () => {
      let fixedNav = "normal";
      if (window.scrollY === 0) {
        fixedNav = null;
      } else {
        fixedNav = "top";
      }
      this.setState({ fixedNav });
      this.state = {
        expand: false,
      };
    });
  }
  // END FIXED NAVBAR

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledServicesNavBar className="servicesNavBar">
        <NanoFlex className={`nav ${this.state.fixedNav ? "fixedNav" : ""}`}>
          <NanoFlex className="navItemsList">
            {/* ITEM */}
            <NanoFlex className="navItem">
              <NavLink to="/services/recrutamento" exact activeClassName="activeNavItem">
                <Type.p>{this.translation.services.navBar.recruitment}</Type.p>
              </NavLink>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="navItem">
              <NavLink to="/services/outsourcing" exact activeClassName="activeNavItem">
                <Type.p>{this.translation.services.navBar.outsourcing}</Type.p>
              </NavLink>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="navItem">
              <NavLink to="/services/consultingRH" exact activeClassName="activeNavItem">
                <Type.p>{this.translation.services.navBar.consultingRH}</Type.p>
              </NavLink>
            </NanoFlex>
            {/*  
            <NanoFlex className="navItem">
              <NavLink to="/services/formation" exact activeClassName="activeNavItem">
                <Type.p>{this.translation.services.navBar.formation}</Type.p>
              </NavLink>
            </NanoFlex> */}
            {/* ITEM */}
            <NanoFlex className="navItem">
              <NavLink to="/services/assessmentCenter" exact activeClassName="activeNavItem">
                <Type.p>{this.translation.services.navBar.assessmentCentet}</Type.p>
              </NavLink>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledServicesNavBar>
    );
  }
}
