import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

//Imgs
import AboutRecruitmentIMG from "./../../../Global/images/aboutRecruitment.jpg";

const StyledAboutRecruitment = styled(NanoFlex)`
  height: auto;
  margin: 56px 0;
  .aboutRecruitmentWrapper {
    position: relative;
    .aboutRecruitmentArticle {
      height: auto;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding: 10%;
      width: 70%;
      justify-content: flex-start;
      padding-right: 25%;
      .paragraphWrapper {
        margin-bottom: 24px;
        height: auto;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .imgWrapper {
      position: absolute;
      width: 40vw;
      height: auto;
      right: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin: 32px 0;
    .aboutRecruitmentWrapper {
      .aboutRecruitmentArticle {
        padding: 40px;
        padding-right: 15%;
      }
    }
  }
  @media only screen and (max-width: 770px) {
    .aboutRecruitmentWrapper {
      .aboutRecruitmentArticle {
        width: 100%;
        /* background: url(${AboutRecruitmentIMG}); */        
        background: linear-gradient(#d1c3dd80,#f3bac0de), url(${AboutRecruitmentIMG});
        background-size: cover;
        padding: 32px 24px;
        .paragraphWrapper {
          p {
            color: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .imgWrapper {
        display: none;
      }
    }
  }
`;

export default class AboutRecruitment extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledAboutRecruitment className="aboutRecruitment">
        <NanoFlex className="aboutRecruitmentWrapper" justifyContent="space-between">
          <NanoFlex className="aboutRecruitmentArticle" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="paragraphWrapper" justifyContent="flex-start">
              <Type.h5>{this.translation.services.recruitment.aboutRecruitment.paragraph1}</Type.h5>
            </NanoFlex>

            <NanoFlex className="paragraphWrapper" flexDirection="column" alignItems="flex-start">
              {this.translation.services.recruitment.aboutRecruitment.list.map((p) => {
                return <Type.h5 key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.h5>;
              })}
            </NanoFlex>

            <NanoFlex className="paragraphWrapper" justifyContent="flex-start">
              <Type.h5>{this.translation.services.recruitment.aboutRecruitment.paragraph2}</Type.h5>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="imgWrapper" justifyContent="flex-start" alignItems="flex-start">
            <img src={AboutRecruitmentIMG} alt="Recruitment" />
          </NanoFlex>
        </NanoFlex>
      </StyledAboutRecruitment>
    );
  }
}
