import React, { Component } from "react";
import "moment/locale/en-gb";
import "moment/locale/pt";
import "moment/locale/pt-br";
import t from "./Global/translate.json";
//import { BaseService } from "../services/BaseService.js";

export default class BaseComponent extends Component {
  constructor(props) {
    super(props);

    this.setLanguage(this.getLanguage());

    //this._user = new BaseService().getUser();
    this._isMobile = this.isMobile();
    this._isTablet = this.isTablet();
  }

  componentDidMount() {
    window.addEventListener("languageChange", this.onLanguageChange, false);
    window.addEventListener("resize", this.onResize, false);
  }

  onLanguageChange = (e) => {
    //Set Language
    this.setLanguage(e.detail);
    //Force Component Update
    this.forceUpdate();
  };

  onResize = (e) => {
    let newIsMobile = this.isMobile();
    if (newIsMobile !== this._isMobile) {
      //Set Is Mobile
      this._isMobile = newIsMobile;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsMobileChange
      window.dispatchEvent(new Event("isMobileChange"));
    }

    let newIsTablet = this.isTablet();
    if (newIsTablet !== this._isTablet) {
      //Set Is Tablet
      this._isTablet = newIsTablet;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsTabletChange
      window.dispatchEvent(new Event("isTabletChange"));
    }
  };

  componentWillUnmount() {
    window.removeEventListener("languageChange", this.onLanguageChange, false);
    window.removeEventListener("resize", this.onResize, false);
  }

  isMobile = () => {
    return window.innerWidth <= 800;
  };

  isTablet = () => {
    return window.innerWidth <= 1280;
  };

  setLanguage = (langId) => {
    let lang = null;
    switch (langId) {
      case 1:
        lang = "br";
        break;
      case 2:
        lang = "en";
        break;
      default:
        if (this.isDomainHr()) {
          lang = "en";
        } else {
          if (this.isDomainBr()) {
            lang = "br";
          } else {
            lang = "pt";
          }
        }
    }
    //Set Local Storage Language Key
    localStorage.setItem("language", lang);
    //Set Translation Variable used on the component
    this.translation = t[lang];
  };

  getLanguage = () => {
    let lang = localStorage.getItem("language");
    switch (lang) {
      case "br":
        return 1;
      case "en":
        return 2;
      default:
        if (this.isDomainHr()) {
          return 2;
        } else {
          if (this.isDomainBr()) {
            return 1;
          } else {
            return null;
          }
        }
    }
  };

  getLanguageText = () => {
    let lang = localStorage.getItem("language");
    if (!lang) {
      if (this.isDomainHr()) {
        lang = "en";
      } else {
        if (this.isDomainBr()) {
          lang = "br";
        } else {
          lang = "pt";
        }
      }
    } else {
      return lang;
    }
  };

  isDomainBr = () => {
    let split = window.location.hostname.split(".");
    // split[split.length - 1].toUpperCase() === "BR";
    return true
  };

  isDomainHr = () => {
    let split = window.location.hostname.split(".");
    // split[split.length - 1].toUpperCase() === "HR";
    return false
  };
}
