import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { validateEmailFormat } from "../../Global/tools.js";

//Imgs
import ContactsIMG from "./../../Global/images/contactsIMG.jpg";

// Componets
import Input from "../../Forms/Input.js";
import Textarea from "../../Forms/Textarea.js";
import MainButton from "../../Buttons/MainButton.js";
import Loading from "../../Animations/Loading.js";

const StyledContactBlock = styled(NanoFlex)`
  /* height: auto; */
  height: 656px;
  /* margin: 64px 0; */
  margin: 0px 0 64px 0;
  .contactImageWrapper {
    height: 564px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .contactFormWrapper {
    height: 564px;
    background-color: ${(props) => props.theme.color.colorsPalette.lilac};
    .contactFormContainer {
      width: auto;
      min-width: 400px;
      height: auto;
      padding: 40px;
      background-color: ${(props) => props.theme.color.main.white};
      -webkit-box-shadow: 6px 0px 21px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 6px 0px 21px rgba(0, 0, 0, 0.25);
      box-shadow: 6px 0px 21px rgba(0, 0, 0, 0.25);

      .contactFormTitle {
        margin-bottom: 40px;
        h3 {
          color: ${(props) => props.theme.color.colorsPalette.yellow};
          text-transform: uppercase;
        }
      }
      .contactForm {
        .formWrapper {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .loadingContainer {
          margin-top: 16px;
          height: 42px;
          .loading {
            padding-bottom: 36px;
          }
        }
      }
    }
    .contactInformation {
      max-height: 50%;
      margin: 0px 30px;
      pointer-events: none;
      text-transform: uppercase;
      .officeName {
        p {
          color: ${(props) => props.theme.color.main.font};
        }
      }
      .officeAddress {
        margin-bottom: 16px;
        display: flex;
        p {
          color: ${(props) => props.theme.color.main.font};
        }
      }
      .officePhone {
        margin-bottom: 7px;
        display: flex;
        p {
          color: ${(props) => props.theme.color.main.font};
        }
      }
      .officeEmail {
        a {
          pointer-events: auto;
          text-decoration: none;
          p {
            color: ${(props) => props.theme.color.main.font};
            font-weight: bold;
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 875px) {
    .contactImageWrapper {
      display: none;
    }
    .contactFormWrapper {
      justify-content: center;
    }
    .contactInformation {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    .contactFormWrapper {
      height: 490px;
      .contactFormContainer {
        width: 90%;
        min-width: auto;
        padding: 24px;
        .contactFormTitle {
          margin-bottom: 24px;
          h3 {
            font-size: 30px;
            line-height: 32px;
          }
        }
      }
    }
  }
`;

export class ContactBlock extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      loading: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  isSendDisabled = () => {
    return this.state.name?.trim() == "" || this.state.email?.trim() == "" || validateEmailFormat(this.state.email?.trim()) == false || this.state.phone?.trim() == "" || this.state.message?.trim() == "";
  };

  sendContactUsEmail = () => {
    this.setState({ loading: true }, () => {
      fetch("api/Home/ContactUsEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
          lang: new BaseComponent().getLanguageText(),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState(this.getDefaultState());
        });
    });
  };

  render() {
    return (
      <StyledContactBlock className="contactBlock" id="contacts">
        <NanoFlex className="contactImageWrapper">
          <img src={ContactsIMG} alt="Contacts Image" />
        </NanoFlex>
        <NanoFlex className="contactFormWrapper" justifyContent="flex-start">
          <NanoFlex className="contactFormContainer" flexDirection="column">
            <NanoFlex className="contactFormTitle">
              <Type.h3>
                <b>{this.translation.global.contactUs}</b>
              </Type.h3>
            </NanoFlex>
            <NanoFlex className="contactForm" flexDirection="column">
              <NanoFlex className="formWrapper">
                <Input defaultValue={this.state.name} onChangeAction={(val) => this.setState({ name: val })} title={this.translation.contactForm.name} placeholder={this.translation.contactForm.namePlaceholder} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input defaultValue={this.state.email} onChangeAction={(val) => this.setState({ email: val })} title={this.translation.contactForm.email} placeholder={this.translation.contactForm.emailPlaceholder} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input defaultValue={this.state.phone} onChangeAction={(val) => this.setState({ phone: val })} title={this.translation.contactForm.phone} placeholder={this.translation.contactForm.phonePlaceholder} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Textarea defaultValue={this.state.message} onChangeAction={(val) => this.setState({ message: val })} title={this.translation.contactForm.message} placeholder={this.translation.contactForm.mesagePlaceholder} />
              </NanoFlex>
              {/* <NanoFlex className="formWrapper">
                <MainButton label={this.translation.global.send} disabled={this.isSendDisabled()} clickAction={this.sendContactUsEmail} />
              </NanoFlex> */}
              {!this.state.loading && (
                <NanoFlex className="formWrapper">
                  <MainButton label={this.translation.global.send} disabled={this.isSendDisabled()} clickAction={this.sendContactUsEmail} />
                </NanoFlex>
              )}
              {this.state.loading && (
                <NanoFlex className="formWrapper loadingContainer">
                  <Loading />
                </NanoFlex>
              )}
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="contactInformation" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="officeName" justifyContent="flex-start">
              <Type.p>
                <b>{this.translation.homepage.contactInfo.name}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="officeAddress" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <Type.p>{this.translation.homepage.contactInfo.address}</Type.p>
              <Type.p>{this.translation.homepage.contactInfo.postalCode}</Type.p>
            </NanoFlex>
            <NanoFlex className="officePhone" justifyContent="flex-start">
              <Type.p>{this.translation.homepage.contactInfo.phone}</Type.p>
            </NanoFlex>
            <NanoFlex className="officeEmail" justifyContent="flex-start">
              <a href={`mailto:${this.translation.homepage.contactInfo.email}`}>
                <Type.p>{this.translation.homepage.contactInfo.email}</Type.p>
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledContactBlock>
    );
  }
}
