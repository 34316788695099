import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import CareerManagementIMG from "./../../Global/images/onceUpon-careerManagement.jpg";
import HRcontultingIMG from "./../../Global/images/onceUpon-hrContulting.jpg";
import RecruitmentIMG from "./../../Global/images/onceUpon-recruitment.jpg";
import TrainingIMG from "./../../Global/images/onceUpon-training.jpg";

const StyledOnceUponBlock = styled(NanoFlex)`
  margin-bottom: 64px;
  .titleBlock {
    padding-left: 40px;
    margin-bottom: 32px;
    h2 {
      b {
        color: ${(props) => props.theme.color.main.blue};
      }
    }
  }

  .sliderAside {
    position: relative;
    height: auto;
    .carouselWrapper {
      overflow: hidden;
      /* CAROUSEL */
      .carousel-root {
        width: 100%;
        height: 100%;
        .carousel-slider {
          width: 100%;
          height: 100%;
          .control-arrow {
            display: none;
          }
          .slider-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            .slider {
              display: flex;
              width: 544px;
              .slide {
                width: auto;
                min-width: auto;
                background-color: unset;
                &:nth-child(even) {
                  margin-top: 48px;
                }
              }
            }
          }
          .control-dots {
            display: none;
          }
          .carousel-status {
            display: none;
          }
        }
        .carousel {
          .axis-vertical {
            display: none;
          }
        }
      }
      /* END CAROUSEL */
    }
  }
  /* CAROUSEL ITEM */
  .carouselItem {
    width: 50vw;
    min-width: 316px;
    max-width: 544px;
    height: 544px;
    cursor: grab;
    overflow: hidden;
    position: relative;
    margin-right: 48px;
    user-select: none;
    .imgItemWrapper {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .itemWrapper {
      position: absolute;
      bottom: 0;
      .titleItem {
        background-color: ${(props) => props.theme.color.main.font};
        padding: 48px 32px;
        height: auto;
        h4 {
          text-transform: uppercase;
          line-height: 0;
          b {
            color: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .articleItemWrapper {
        height: 0;
        overflow: hidden;
        margin: 16px 0;
        display: none;
        .article {
          margin-bottom: 16px;
          p {
            text-align: left;
            color: ${(props) => props.theme.color.main.white};
          }
        }
        .nameFrom {
          height: auto;
          .name,
          .from {
            h6 {
              color: ${(props) => props.theme.color.main.white};
            }
          }
        }
      }
    }

    &:hover {
      transition: ${(props) => props.theme.transition};
      .imgItemWrapper {
        img {
          width: 120%;
          height: 120%;
          object-fit: cover;
          transition: ${(props) => props.theme.transition};
        }
      }
      .itemWrapper {
        justify-content: space-between;
        height: 100%;
        background-color: rgba(52, 52, 52, 0.9);
        .titleItem {
          padding-bottom: 16px;
          background-color: transparent;
          transition: ${(props) => props.theme.transition};
        }
        .articleItemWrapper {
          display: flex;
          height: 100%;
          padding: 0 32px;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1280px) {
    .sliderAside {
      .carouselWrapper {
        .carousel-root {
          .carousel-slider {
            .slider-wrapper {
              .slider {
                .slide {
                  &:nth-child(even) {
                    margin-top: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 870px) {
    .sliderAside {
      .carouselWrapper {
        .carousel-root {
          .carousel-slider {
            .slider-wrapper {
              .slider {
                width: 90vw;
                min-width: 310px;
                .slide {
                  width: calc(100% - 24px);
                  min-width: calc(100% - 24px);
                  margin-right: 24px;
                }
              }
            }
          }
        }
      }
    }
    .carouselItem {
      margin-right: 24px;
      width: 100%;
      height: 510px;
      .itemWrapper {
        .titleItem {
          padding: 32px 16px;
          h4 {
            font-size: 20px;
          }
        }
        .articleItemWrapper {
          .article {
            p {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .titleBlock {
      padding: 0 24px;
      margin-bottom: 24px;
      h2 {
        font-size: 42px;
        line-height: inherit;
      }
    }
  }
`;

export default class OnceUponBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledOnceUponBlock className="onceUponBlock" flexDirection="column">
        <NanoFlex className="titleBlock" justifyContent="flex-start">
          <Type.h2>
            <b>{this.translation.homepage.onceUponBlock.titleBlock}</b>
          </Type.h2>
        </NanoFlex>
        <NanoFlex className="sliderAside">
          <NanoFlex className="carouselWrapper">
            <Carousel autoPlay infiniteLoop emulateTouch useKeyboardArrows stopOnHover>
              {/* CAROUSEL ITEM - CAREER MANAGEMENT*/}
              <NanoFlex className="carouselItem">
                <NanoFlex className="imgItemWrapper">
                  <img src={CareerManagementIMG} alt="Career Management" />
                </NanoFlex>
                <NanoFlex className="itemWrapper" flexDirection="column" justifyContent="flex-end">
                  <NanoFlex className="titleItem" justifyContent="flex-start">
                    <Type.h4>
                      <b>{this.translation.homepage.onceUponBlock.careerManagement.title}</b>
                    </Type.h4>
                  </NanoFlex>
                  <NanoFlex className="articleItemWrapper" flexDirection="column">
                    <NanoFlex className="article">
                      <Type.p>"{this.translation.homepage.onceUponBlock.careerManagement.article}"</Type.p>
                    </NanoFlex>
                    <NanoFlex className="nameFrom" flexDirection="column">
                      <NanoFlex className="name" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.careerManagement.name}</Type.h6>
                      </NanoFlex>
                      <NanoFlex className="from" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.careerManagement.from}</Type.h6>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* CAROUSEL ITEM - HR Consulting*/}
              <NanoFlex className="carouselItem">
                <NanoFlex className="imgItemWrapper">
                  <img src={HRcontultingIMG} alt="HR Consulting" />
                </NanoFlex>
                <NanoFlex className="itemWrapper" flexDirection="column" justifyContent="flex-end">
                  <NanoFlex className="titleItem" justifyContent="flex-start">
                    <Type.h4>
                      <b>{this.translation.homepage.onceUponBlock.hrContulting.title}</b>
                    </Type.h4>
                  </NanoFlex>
                  <NanoFlex className="articleItemWrapper" flexDirection="column">
                    <NanoFlex className="article">
                      <Type.p>"{this.translation.homepage.onceUponBlock.hrContulting.article}"</Type.p>
                    </NanoFlex>
                    <NanoFlex className="nameFrom" flexDirection="column">
                      <NanoFlex className="name" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.hrContulting.name}</Type.h6>
                      </NanoFlex>
                      <NanoFlex className="from" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.hrContulting.from}</Type.h6>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* CAROUSEL ITEM - Training*/}
              <NanoFlex className="carouselItem">
                <NanoFlex className="imgItemWrapper">
                  <img src={TrainingIMG} alt="Training" />
                </NanoFlex>
                <NanoFlex className="itemWrapper" flexDirection="column" justifyContent="flex-end">
                  <NanoFlex className="titleItem" justifyContent="flex-start">
                    <Type.h4>
                      <b>{this.translation.homepage.onceUponBlock.training.title}</b>
                    </Type.h4>
                  </NanoFlex>
                  <NanoFlex className="articleItemWrapper" flexDirection="column">
                    <NanoFlex className="article">
                      <Type.p>"{this.translation.homepage.onceUponBlock.training.article}"</Type.p>
                    </NanoFlex>
                    <NanoFlex className="nameFrom" flexDirection="column">
                      <NanoFlex className="name" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.training.name}</Type.h6>
                      </NanoFlex>
                      <NanoFlex className="from" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.training.from}</Type.h6>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* CAROUSEL ITEM - Recruitment*/}
              <NanoFlex className="carouselItem">
                <NanoFlex className="imgItemWrapper">
                  <img src={RecruitmentIMG} alt="Recruitment" />
                </NanoFlex>
                <NanoFlex className="itemWrapper" flexDirection="column" justifyContent="flex-end">
                  <NanoFlex className="titleItem" justifyContent="flex-start">
                    <Type.h4>
                      <b>{this.translation.homepage.onceUponBlock.recruitment.title}</b>
                    </Type.h4>
                  </NanoFlex>
                  <NanoFlex className="articleItemWrapper" flexDirection="column">
                    <NanoFlex className="article">
                      <Type.p>"{this.translation.homepage.onceUponBlock.recruitment.article}"</Type.p>
                    </NanoFlex>
                    <NanoFlex className="nameFrom" flexDirection="column">
                      <NanoFlex className="name" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.recruitment.name}</Type.h6>
                      </NanoFlex>
                      <NanoFlex className="from" justifyContent="flex-end" alignItems="flex-end">
                        <Type.h6>{this.translation.homepage.onceUponBlock.recruitment.from}</Type.h6>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </Carousel>
          </NanoFlex>
        </NanoFlex>
      </StyledOnceUponBlock>
    );
  }
}
