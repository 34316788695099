import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

const StyledListBlock = styled(NanoFlex)`
  height: auto;
  margin-bottom: 56px;
  .listBlockWrapper {
    .titleBlockWrapper {
      width: 70%;
      margin-left: -130px;
      z-index: 1;
      .titleBlock {
        width: 60%;
        padding: 32px 48px 32px 0px;
        h3 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.main.blue};
          font-weight: bold;
          line-height: 56px;
          em {
            color: ${(props) => props.theme.color.colorsPalette.lightOrange};
            font-size: 52px;
          }
        }
      }
    }

    .listContainer {
      background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
      padding: 48px 240px 48px 48px;

      .listItem {
        margin-bottom: 24px;
        text-align: right;
        h5 {
          text-transform: uppercase;
          b {
            color: ${(props) => props.theme.color.main.blue};
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 880px) {
    .listBlockWrapper {
      flex-direction: column;
      background: linear-gradient(180deg, #ffffff 15%, #e7f3fe 15%);
      .titleBlockWrapper {
        order: 1;
        margin-left: 0;
        width: 100%;
        justify-content: center;
        .titleBlock {
          min-width: 100%;
          padding: 0 32px;
        }
      }
      .listContainer {
        order: 2;
        padding: 32px;
        justify-content: center;
        align-items: flex-start;
        background-color: unset;
        .listItem {
          width: auto;
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    .listBlockWrapper {
      .titleBlockWrapper {
        .titleBlock {
          padding: 0 16px;
          h3 {
            font-size: 28px;
            line-height: 36px;
            em {
              font-size: 36px;
            }
          }
        }
      }
      .listContainer {
        padding: 24px 16px;
        .listItem {
          margin-bottom: 16px;
          h5 {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
`;

export default class ListBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledListBlock className="listBlock">
        <NanoFlex className="listBlockWrapper">
          <NanoFlex className="listContainer" flexDirection="column" justifyContent="flex-end">
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-end">
              <Type.h5>{this.translation.services.formation.listBlock.list.item1}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-end">
              <Type.h5>{this.translation.services.formation.listBlock.list.item2}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-end">
              <Type.h5>{this.translation.services.formation.listBlock.list.item3}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-end">
              <Type.h5>{this.translation.services.formation.listBlock.list.item4}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-end">
              <Type.h5>{this.translation.services.formation.listBlock.list.item5}</Type.h5>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="titleBlockWrapper" justifyContent="flex-start">
            <NanoFlex className="titleBlock">
              <Type.h3 dangerouslySetInnerHTML={{ __html: this.translation.services.formation.listBlock.title }}></Type.h3>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledListBlock>
    );
  }
}
