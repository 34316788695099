import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import HeaderServices from "../HeaderServices.js";
import FormationsList from "./FormationsList.js";
import ListBlock from "./ListBlock.js";

//Imgs
import FormationHeader from "./../../../Global/images/formationHeader.jpg";

const StyledFormation = styled(NanoFlex)`
  height: auto;
`;

export default class Formation extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFormation flexDirection={"column"}>
        <HeaderServices violet title={this.translation.services.formation.header.title} article={this.translation.services.formation.header.article} src={FormationHeader} />
        <FormationsList />
        <ListBlock />
      </StyledFormation>
    );
  }
}
